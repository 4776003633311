import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    FormatElementTableCellDateTime, ElementTablePageOptions,
    EElementTableColFilterType,
    TableColSortEntry
} from '../general/ElementTableUtil';
import * as T from '../utils/TypeUtil';

import { EAttachmentField, EAttachmentFieldLabel, EElementType } from './EntityData';
import '../general/css/general.css';
import BootAlert from '../general/BootAlert';
import { fetchCurrentUser, fetchWebApi } from '../utils/ClientUtil';
import { displayError } from '../utils/ExceptionUtil';
import { displayFileUploadDialog, renderOptionsCell } from './EntityEditorUtil';
import ComponentModal from '../general/ComponentModal';
import { URL_ATTACHMENT } from '../AppConstants';
import { faDownload, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import BootPanel from '../general/BootPanel';
import { Input } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
const EField = EAttachmentField;

export const FileContentTypes = {
    Image: [
        'image/gif',
        'image/jpeg',
        'image/png',
    ],
    Text: [
        'text/css',
        'text/csv',
        'text/html',
        'text/plain',
        'text/xml',
    ],
    Pdf: [
        'application/pdf'
    ]
};

/**
 * @param name [optional] component name
 * @param ref [optional]
 * @param sizePerPage [optional] number of rows per page
 */
export class AttachmentTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refAlert = undefined;
        this.refModal = undefined;
        this.refAttachmentViewer = undefined;

        const { link, name, sizePerPage } = this.props;
        this.name = name || 'tableAttachment';

        this.state = {
            loginUserId: undefined,
            loginUsername: undefined,
            data: [],
            pageOptions: new ElementTablePageOptions(false, sizePerPage),
            defaultSorted: [
                new TableColSortEntry(EField.CreatedOn, true)
            ],
        };

        this.columns = this.getColumns();

        if (T.IsFunc(link)) {
            link(this);
        }

        this.reloadTable = this.reloadTable.bind(this);
        this.handleLoadTableData = this.handleLoadTableData.bind(this);
        this.handleButtonNewClick = this.handleButtonNewClick.bind(this);
        this.handleButtonDeleteClick = this.handleButtonDeleteClick.bind(this);
        this.handleButtonViewClick = this.handleButtonViewClick.bind(this);
        this.handleButtonDownloadClick = this.handleButtonDownloadClick.bind(this);
        this.renderRowOptions = this.renderRowOptions.bind(this);
    }

    reloadTable() {
        this.refTable.reloadTable();
        this.setState({ selectedFile: undefined });
    }

    componentDidMount() {
        this.mount = true;
        this._subscription = authService.subscribe(() => this.populateUserInfo());
        this.populateUserInfo();
    }
    componentWillUnmount() {
        this.mount = false;
        authService.unsubscribe(this._subscription);
    }
    componentDidUpdate(prevProps, prevState) {
        const { sizePerPage } = this.props;

        if (prevProps.sizePerPage !== sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            this.setState({ pageOptions: updatedPageOptions });
        }
    }

    async populateUserInfo() {
        try {
            const user = await fetchCurrentUser();
            this.setState(user);
        } catch (error) {
            console.log('populateUserInfo ERROR', error);
            this.setState({ loginUserId: undefined, loginUsername: undefined });
        }
    }

    getColumns() {
        const { parentType } = this.props;
        const isPolicyView = parentType === EElementType.Policy;
        const isTaskView = parentType === EElementType.Task;
        const isUserView = parentType === EElementType.User;

        const cols = [
            {
                text: EField.Id,
                dataField: EField.Id,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EField.Name,
                dataField: EAttachmentFieldLabel.Name,
                filter: EElementTableColFilterType.TEXT,
                headerStyle: { width: '75%' },
            },
            {
                text: EAttachmentFieldLabel.ContentType,
                dataField: EField.ContentType,
                filter: EElementTableColFilterType.TEXT,
            }
        ];
        if (!isPolicyView) {
            cols.push(
                {
                    text: EAttachmentFieldLabel.AssociatedPolicyName,
                    dataField: EField.AssociatedPolicyName,
                    filter: EElementTableColFilterType.TEXT,
                }
            );
        }
        if (!isTaskView) {
            cols.push(
                {
                    text: EAttachmentFieldLabel.AssociatedTaskName,
                    dataField: EField.AssociatedTaskName,
                    filter: EElementTableColFilterType.TEXT,
                }
            );
        }
        if (!isUserView) {
            cols.push(
                {
                    text: EAttachmentFieldLabel.TargetUserFullName,
                    dataField: EField.TargetUserFullName,
                    filter: EElementTableColFilterType.TEXT,
                }
            );
        }
        cols.push(
            {
                text: EAttachmentFieldLabel.CreatedBy,
                dataField: EField.CreatedBy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EAttachmentFieldLabel.CreatedOn,
                dataField: EField.CreatedOn,
                formatter: FormatElementTableCellDateTime,
                headerStyle: { width: '13%' },
            },
            {
                text: '',
                dataField: 'options',
                isDummyField: true,
                editable: false,
                sort: false,
                allowFilter: false,
                formatter: (cellVal, row) => {
                    return this.renderRowOptions(row);
                },
            });

        return cols;
    }

    renderRowOptions(element) {

        const { parentType } = this.props;
        const { loginUserId } = this.state;
        const eId = element.Id;
        const downloadUrl = `${URL_ATTACHMENT}/${eId}/download`;

        const isPolicy = T.IsDefined(element[EField.AssociatedPolicyId]);
        const isTask = T.IsDefined(element[EField.AssociatedTaskId]);

        let canDelete = loginUserId === element[EField.CreatedById];
        if (canDelete) {
            if (parentType === EElementType.Policy) {
                canDelete = !isTask;
            }
            else if (parentType === EElementType.Task) {
                canDelete = !isPolicy;
            }
            else if (parentType === EElementType.User) {
                canDelete = !(isTask || isPolicy);
            }
        }

        const options = [
            {
                name: 'View',
                onClick: () => this.handleButtonViewClick(element, downloadUrl),
                faIcon: faEye
            },
            {
                name: 'Download',
                onClick: () => this.handleButtonDownloadClick(element, downloadUrl),
                faIcon: faDownload
            },
            {
                name: 'Delete',
                disabled: !(canDelete && element[EField.CreatedById] === loginUserId),
                onClick: () => this.handleButtonDeleteClick(element),
                faIcon: faTrash
            }
        ];
        return renderOptionsCell(`${this.name}-${eId}`, options);
    }

    handleLoadTableData() {
        this.refAlert.hide();

        const { ownerId, policyId, taskId } = this.props;
        const urlParam = policyId
            ? `?policyId=${policyId}`
            : (taskId
                ? `?taskId=${taskId}`
                : (ownerId
                    ? `?ownerId=${ownerId}`
                    : '')
            );

        const msg = 'Error retrieving attachments.';
        fetchWebApi(`${URL_ATTACHMENT}${urlParam}`) // load all
            .then((result) => {
                this.setState({ data: result, selectedFile: undefined });
            })
            .catch((error) => {
                displayError(this.refAlert, msg, error);
            });
    }

    handleButtonNewClick(e) {

        const { ownerId, policyId, taskId } = this.props;
        const urlParams = [
            `ownerId=${ownerId || ''}`,
            `policyId=${policyId || ''}`,
            `taskId=${taskId || ''}`
        ];
        const url = `${URL_ATTACHMENT}?${urlParams.join('&')}` //create

        displayFileUploadDialog('Upload Attachment', this.refModal, url,
            () => {
                if (!this.mount) return;

                //reload the table in case changes affect sorting/filter
                this.reloadTable();
            }
        );
    }

    handleButtonDeleteClick(element) {
        const eId = element[EField.Id];
        const eName = element[EField.Name];

        this.refModal.Confirm(`Are you sure you want to delete ${eName}?`,
            'This action cannot be undone.',
            () => { // OK

                this.refModal.Loading('Deleting attachment...');
                fetchWebApi(`${URL_ATTACHMENT}/${eId}`, { method: 'DELETE' })
                    .then(() => {
                        if (!this.mount) return;
                        this.refModal.Success('Successfully deleted attachment', '',
                            () => {
                                this.refModal.close();
                                this.reloadTable();
                            });
                    })
                    .catch((error) => {
                        if (!this.mount) return;
                        displayError(this.refModal, 'Error deleting attachment', error);
                    });
            },
            false,
            'Yes',
            'No');
    }

    handleButtonViewClick(element, downloadUrl) {
        const eId = element[EField.Id];
        const eName = element[EField.Name];
        const eType = element[EField.ContentType];

        if (FileContentTypes.Image.find((x) => x === eType)) {

            this.refModal.Loading(`Fetching image attachment...`);
            fetchWebApi(downloadUrl)
                .then((result) => {
                    result.blob()
                        .then((blob) => {
                            if (!this.mount) return;

                            const file = { ...element };
                            file.Content = window.URL.createObjectURL(blob);
                            this.setState({ selectedFile: file });
                            this.refModal.close();
                        });
                })
                .catch((error) => {
                    if (!this.mount) return;
                    displayError(this.refModal, 'Error fetching image attachment data', error);
                });
        }
        else if (FileContentTypes.Text.find((x) => x === eType)) {

            this.refModal.Loading(`Fetching text attachment...${eName}`);
            fetchWebApi(`${URL_ATTACHMENT}/${eId}/text`) //view
                .then((result) => {
                    if (!this.mount) return;

                    const file = { ...element, ...result };
                    this.setState({ selectedFile: file });
                    this.refModal.close();
                })
                .catch((error) => {
                    if (!this.mount) return;
                    displayError(this.refModal, 'Error fetching text attachment data', error);
                });
        }
    }

    handleButtonDownloadClick(element, downloadUrl) {
        const eName = element[EField.Name];

        this.refModal.Loading(`Fetching attachment...`);
        fetchWebApi(downloadUrl)
            .then((result) => {
                result.blob()
                    .then((blob) => {
                        if (!this.mount) return;

                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = eName;
                        link.click();

                        this.refModal.close();
                    });
            })
            .catch((error) => {
                if (!this.mount) return;
                displayError(this.refModal, 'Error fetching attachment data', error);
            });
    }

    renderFile(file) {
        const {
            Name: name,
            ContentType: type,
            Content: body
        } = file;
        console.log(`file [name=${name}, type=${type}]`);

        let fileBody = undefined;
        if (FileContentTypes.Image.find((x) => x === type)) {
            const style = {
                width: '100%',
                objectFit: 'scale-down'
            };
            fileBody = (
                <div>
                    <img src={body} style={style} alt={name} />
                </div>
            );
        }
        else if (FileContentTypes.Text.find((x) => x === type)) {
            fileBody = <Input
                value={body}
                type="textarea"
                key='attachment-text'
                rows={5}
                readOnly
            />
        }
        // else if (FileContentTypes.Pdf.find((x) => x === type)) {
        // }
        else {
            fileBody = <span>display not yet supported for {type}</span>;
        }

        return (
            <BootPanel head={name} toggle open>
                {fileBody}
            </BootPanel>
        );
    }

    render() {

        const { selectRowOptions } = this.props;
        const {
            pageOptions, defaultSorted, data,
            selectedFile
        } = this.state;
        const sId = `${this.name}_eTable`;

        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModal = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <span>{this.name}</span>
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={data}
                    disableLoadOnInit={this.props.disableLoadOnInit}
                    link={(c) => { this.refTable = c; }}
                    columns={this.columns}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                    selectRowOptions={selectRowOptions}
                    onLoadTableData={this.handleLoadTableData}
                    onNew={this.handleButtonNewClick}
                ></ElementTable>
                {
                    selectedFile
                    && this.renderFile(selectedFile)
                }
            </React.Fragment>
        );
    }
}
