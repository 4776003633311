import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ComponentModal from '../general/ComponentModal';

import * as T from '../utils/TypeUtil';
import { PersonEditor } from './PersonEditor';
import { EPersonTableMode, PersonTable } from './PersonTable';
import { PolicyTable } from './PolicyTable';
import { TabControl } from '../general/TabControl';
import { renderButton } from '../utils/EditorInputUtil';

import '../general/css/general.css';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { displayPersonEditorDialog, formatPerson } from './EntityEditorUtil';
import { TaskTable } from './TaskTable';
import { AttachmentTable } from './AttachmentTable';
import { EElementType } from './EntityData';
import { LogEntryTable } from './LogEntryTable';
import { PersonNoteTable } from './PersonNoteTable';

const ETab = {
    NOTE: 'Notes',
    POLICY: 'Policies',
    TASK: 'Tasks',
    REL: 'Relationships',
    ATTACHMENT: 'Attachments',
    LOGS: 'History'
};

/**
 * @param loginUserId ID of the login user
 * @param id ID of the person
 * @param name [optional] component name
 * @param onPersonChanged [optional] raises when person data has changed
 * @param onTaskChanged [optional] raises when task is created/updated
 * @param onPolicyChanged [optional] raises when person data has changed
 * @param onAddressChanged [optional] raises when address is created/updated
 */
export class PersonWorkspace extends Component {

    constructor(props) {
        super(props);

        this.mount = false;
        this.refModal = undefined;

        this.refEditor = undefined;

        this.refPolicyTable = undefined;
        this.refRelTable = undefined;
        this.refTaskTable = undefined;
        this.refAttachmentTable = undefined;
        this.refLogTable = undefined;
        this.refNoteTable = undefined;

        const { name } = this.props;
        this.name = name || 'personWS';

        this.state = {
            activeTabId: ETab.NOTE
        };

        if (T.IsFunc(props.link)) {
            props.link(this);
        }

        this.renderPoliciesTab = this.renderPoliciesTab.bind(this);
        this.renderTaskTab = this.renderTaskTab.bind(this);
        this.renderRelTab = this.renderRelTab.bind(this);
        this.renderLogsTab = this.renderLogsTab.bind(this);
        this.renderNoteTab = this.renderNoteTab.bind(this);
        this.reloadTab = this.reloadTab.bind(this);

        this.handleTabSelectionChanged = this.handleTabSelectionChanged.bind(this);
        this.handleEditButtonClicked = this.handleEditButtonClicked.bind(this);
    }

    reload() {
        if (this.refEditor) this.refEditor.reload();
        this.reloadTab(this.state.activeTabId);
    }

    componentWillUnmount() {
        this.mount = false;
    }

    componentDidMount() {
        this.mount = true;
    }
    componentDidUpdate(prevProps, prevState) {
        const { activeTabId } = this.state;
        const { activeTabId: prevActiveTabId } = prevState;

        if (activeTabId !== prevActiveTabId) {
            this.reloadTab(activeTabId);
        }
    }

    reloadTab(tabId) {
        switch (tabId) {
            case ETab.NOTE:
                if (this.refNoteTable) this.refNoteTable.reloadTable();
                break;
            case ETab.POLICY:
                if (this.refPolicyTable) this.refPolicyTable.reloadTable();
                break;
            case ETab.TASK:
                if (this.refTaskTable) this.refTaskTable.reloadTable();
                break;
            case ETab.REL:
                if (this.refRelTable) this.refRelTable.reloadTable();
                break;
            case ETab.ATTACHMENT:
                if (this.refAttachmentTable) this.refAttachmentTable.reloadTable();
                break;
            case ETab.LOGS:
                if (this.refLogTable) this.refLogTable.reloadTable();
                break;
            default:
                break;
        }
    }

    handleTabSelectionChanged(tabId) {
        this.setState({ activeTabId: tabId });
    }

    handleEditButtonClicked() {
        const { id, onPersonChanged: h } = this.props;
        displayPersonEditorDialog(this.refModal, `${this.name}_addEditContact`, id,
            (updated) => {
                if (!updated || !this.mount) return;

                if (this.refEditor) {
                    this.refEditor.reload();
                }
                if (T.IsFunc(h)) {
                    h(updated)
                }
            },
            this.props.disabled,
            true, //editing
            true // hide attachments
        );
    }

    renderPoliciesTab(loadOnInit) {
        const { id } = this.props;
        return (
            <PolicyTable id={id}
                name={`${this.name}-policies`}
                onElementChanged={this.props.onPolicyChanged}
                disableLoadOnInit={!loadOnInit}
                link={(e) => this.refPolicyTable = e}
            />);
    }

    renderTaskTab(loadOnInit) {
        const { id, disabled, readOnly } = this.props;
        return (<TaskTable userId={id}
            name={`${this.name}-tasks`}
            disabled={disabled || readOnly}
            disableLoadOnInit={!loadOnInit}
            canGoToContact={false}
            canGoToPolicy={false}
            link={(e) => this.refTaskTable = e}
            onElementChanged={this.props.onTaskChanged}
        />);
    }

    renderRelTab(loadOnInit) {
        const { id, disabled, readOnly } = this.props;
        return (<PersonTable userId={id} displayMode={EPersonTableMode.REL}
            hideAttachments hideLogs hideNotes
            name={`${this.name}-persons`}
            elementName={this.state.elementName}
            disabled={disabled || readOnly}
            disableLoadOnInit={!loadOnInit}
            link={(e) => this.refRelTable = e}
            onElementChanged={this.props.onPersonChanged}
        />);
    }

    renderNoteTab(loadOnInit) {
        const { id, disabled, readOnly } = this.props;
        return (<PersonNoteTable name={`${this.name}_notes`}
            id={id}
            elementName={this.state.elementName}
            disabled={disabled || readOnly}
            disableLoadOnInit={!loadOnInit}
            link={(e) => this.refNoteTable = e}
        />);
    }

    renderLogsTab(loadOnInit) {
        const { id, disabled, readOnly } = this.props;
        return (<LogEntryTable parentId={id} parentType={EElementType.User}
            name={`${this.name}-logs`}
            disabled={disabled || readOnly}
            disableLoadOnInit={!loadOnInit}
            link={(e) => this.refLogTable = e}
            onPersonChanged={this.props.onPersonChanged}
            onTaskChanged={this.props.onTaskChanged}
            onPolicyChanged={this.props.onPolicyChanged}
            onAddressChanged={this.props.onAddressChanged}
        />);
    }

    buildTabInfos(tabId) {
        var tabs = [
            {
                id: ETab.NOTE,
                title: ETab.NOTE,
                body: this.renderNoteTab(tabId === ETab.NOTE),
            },
            {
                id: ETab.POLICY,
                title: ETab.POLICY,
                body: this.renderPoliciesTab(tabId === ETab.POLICY),
            },
            {
                id: ETab.TASK,
                title: ETab.TASK,
                body: this.renderTaskTab(tabId === ETab.TASK),
            },
            {
                id: ETab.REL,
                title: ETab.REL,
                body: this.renderRelTab(tabId === ETab.REL),
            },
            {
                id: ETab.ATTACHMENT,
                title: ETab.ATTACHMENT,
                body: (
                    <AttachmentTable ownerId={this.state.id} parentType={EElementType.User}
                        name={`${this.name}_files`}
                        link={(e) => this.refAttachmentTable = e}
                        disableLoadOnInit={tabId !== ETab.ATTACHMENT}
                    />
                )
            },
            {
                id: ETab.LOGS,
                title: ETab.LOGS,
                body: this.renderLogsTab(tabId === ETab.LOGS),
            },
        ];
        return tabs;
    }

    render() {
        const { id, disabled } = this.props;
        const { activeTabId, readOnly } = this.state;

        const read = disabled || readOnly;
        const tabInfos = this.buildTabInfos(activeTabId);

        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModal = e} size='xl' />
                <Row className='mb-2'>
                    <Col className='col-sm-10'>
                        <Row>
                            <Col style={{ textAlign: 'end' }}>
                                {
                                    renderButton(`${this.name}-btnEdit`, 'Edit',
                                        () => { this.handleEditButtonClicked() },
                                        read, '', faEdit)
                                }
                            </Col>
                        </Row>
                        <PersonEditor id={id} disabled inline hideAttachments hideLogs hideNotes
                            key={`person-${id}`}
                            onElementLoaded={() => {
                                const { id, data } = this.refEditor.state;
                                if (id && id !== T.EmptyGuid) {
                                    this.setState({ elementName: formatPerson(data) });
                                }
                            }}
                            link={(e) => this.refEditor = e} />
                    </Col>
                </Row>
                <hr />
                <Row className='mb-2'>
                    <Col>
                        <TabControl tabInfos={tabInfos} activeTabId={activeTabId}
                            onTabSelectionChanged={this.handleTabSelectionChanged}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
