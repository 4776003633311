
export const JSON_ATTRIB_TYPE = '$type';
export const EmptyGuid = '00000000-0000-0000-0000-000000000000';
export const EmptyString = '';
export const NumericValueCheck = new RegExp('^\\-?(\\d+\\.?)?\\d*$');

export const DataTypes = {
    BoolType: 0,
    DateTimeType: 1,
    DoubleType: 2,
    IntType: 3,
    StringType: 4,
    EnumerationType: 5,
    IndexedEnumerationType: 8
};

// Utility functions for variable type checking
export function IsDefined(variable) {
    return (
        variable !== undefined // Not undefined
        && variable !== null // Not null
        && !(IsString(variable) && !variable) // And if a string, not empty
    );
}

export function IsArrayNonEmpty(variable) {
    return IsArray(variable) && variable.length > 0;
}
export function IsArrayEmpty(variable) {
    return !IsArray(variable) || variable.length === 0;
}

export function IsType(variable, type) { return (typeof (variable) === type); }
export function IsFunc(variable) { return IsType(variable, 'function'); }
export function IsString(variable) { return IsType(variable, 'string'); }
export function IsNum(variable) { return IsType(variable, 'number'); }
export function IsBool(variable) { return IsType(variable, 'boolean'); }
export function IsObject(variable) { return IsType(variable, 'object'); }
export function IsArray(variable) { return Array.isArray(variable); }

// Utility function to check if a component ref has
// actualized by checking if the setState function is present.
export function CheckRef(ref) { return (IsDefined(ref) && IsFunc(ref.setState)); }

export function CheckPtr(ptr) { return IsObject(ptr) && IsFunc(ptr.pointers); }

// Functions to get default values for particular types.
export function DefaultString(variable, defaultVal) {
    return (IsString(variable))
        ? variable
        : defaultVal;
}

export function DefaultNum(variable, defaultVal) { return (IsNum(variable)) ? variable : (IsDefined(defaultVal) && IsNum(defaultVal)) ? defaultVal : null; }

export function DefaultBool(variable, defaultVal) { return (IsBool(variable)) ? variable : (IsDefined(defaultVal) && IsBool(defaultVal)) ? defaultVal : null; }

export function DefaultObject(variable, defaultVal) { return (IsObject(variable)) ? variable : (IsDefined(defaultVal) && IsObject(defaultVal)) ? defaultVal : null; }

export function DefaultArray(variable, defaultVal) { return (IsArray(variable)) ? variable : (IsDefined(defaultVal) && IsArray(defaultVal)) ? defaultVal : null; }

export const stringIsNullOrEmpty = (stringToCheck) => {
    if (stringToCheck === null || stringToCheck === undefined) {
        return true;
    }
    const checkedString = stringToCheck.toString();
    return (checkedString.length === 0 || !checkedString.trim());
};
