import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner, Input, } from 'reactstrap';

import { fetchAcaDailyStatus } from './EntityUtil';
import BootAlert from '../general/BootAlert';
import { EColor } from '../utils/DataFormatUtil';
import { ElementTable } from '../general/ElementTable';
import { IsArrayNonEmpty } from '../utils/TypeUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faCopy, faX } from '@fortawesome/free-solid-svg-icons';
import { handleCopyInputDateToClipboardClick, handleInputDatePasteAction, renderButton } from '../utils/EditorInputUtil';

export function AcaActivityStatusEditor({ userName }) {

    const now = new Date().toISOString().split('T')[0];
    const [loading, setLoading] = useState(true);
    const [reloadCnt, setReloadCnt] = useState(1);
    const [policyYear, setPolicyYear] = useState(2025);
    const [dateStart, setDateStart] = useState(now);
    const [dateEnd, setDateEnd] = useState(now);
    const [fetchResult, setFetchResult] = useState({
        data: undefined,
        error: undefined
    });

    useEffect(() => {
        if (!userName) {
            setLoading(false);
            setFetchResult({
                data: undefined,
                error: undefined
            });
            return;
        }
        setLoading(true);
        fetchAcaDailyStatus(undefined, dateStart, dateEnd,
            (result, error) => {
                setFetchResult({
                    data: result,
                    error
                });
                setLoading(false);
            });
    }, [userName, dateStart, dateEnd, policyYear, reloadCnt]);

    const { data, error: fetchError } = fetchResult;
    const { creates, stateChanges: updates, users } = data || {};
    const newTotal = IsArrayNonEmpty(creates)
        ? creates.map(x => x.count).reduce((a, b) => a + b)
        : 0;
    const updateTotal = IsArrayNonEmpty(updates)
        ? updates.map(x => x.count).reduce((a, b) => a + b)
        : 0;
    const sNewTitle = `New Policies [${newTotal}]`;
    const sUpdateTitle = `Updated Policies [${updateTotal}]`;


    return (
        <React.Fragment>
            {loading && <Spinner />}
            {
                fetchError &&
                <BootAlert key='status-fetch-error'
                    canToggle={false}
                    icon='error'
                    visible={true}
                    message={fetchError}
                    color={EColor.Danger} />
            }
            {
                userName &&
                <Row className='mb-4'>
                    {/* <Col className='col-sm-3'
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center'
                        }}>
                        <span className='me-4 text-primary'>ACA Policy Year</span>
                        <Input type='number'
                            value={policyYear}
                            key={'acaDailyStatus-yy'}
                            id={`acaDailyStatus_yy}`}
                            readOnly={loading}
                            min='2025'
                            max='9999'
                            onChange={(evt) => {
                                const val = evt.target.value;
                                setPolicyYear(val < 2025
                                    ? 2025
                                    : val > 9999
                                        ? 9999
                                        : val);
                            }}
                        />
                        <FontAwesomeIcon icon={faX}
                            className='ms-1'
                            onClick={() => setDateStart('')}
                        />
                    </Col> */}
                    <Col className='col-sm-3'
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center'
                        }}>
                        <span className='me-4 text-primary'>Start Date</span>
                        <Input type='date'
                            value={dateStart}
                            key={'acaDailyStatus-start'}
                            id={`acaDailyStatus_start}`}
                            readOnly={loading}
                            onPaste={(evt) => {
                                handleInputDatePasteAction(evt, (val) => setDateStart(val));
                            }}
                            onChange={(evt) => {
                                const val = evt.target.value;
                                setDateStart(val);
                                if (val
                                    && dateEnd
                                    && new Date(val) > new Date(dateEnd)) {
                                    setDateEnd(val);
                                }
                            }}
                        />
                        <FontAwesomeIcon icon={faX}
                            className='ms-1'
                            onClick={() => setDateStart('')}
                        />
                        <FontAwesomeIcon icon={faCopy}
                            className='ms-1'
                            onClick={() => handleCopyInputDateToClipboardClick(dateStart, true)}
                        />
                    </Col>
                    <Col className='col-sm-3'
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center'
                        }}>
                        <span className='me-4 text-primary'>End Date</span>
                        <Input type='date'
                            value={dateEnd}
                            key={'acaDailyStatus-end'}
                            id={`acaDailyStatus_end}`}
                            readOnly={loading}
                            onPaste={(evt) => {
                                handleInputDatePasteAction(evt, (val) => setDateEnd(val));
                            }}
                            onChange={(evt) => {
                                const val = evt.target.value;
                                setDateEnd(val);
                                if (val
                                    && dateStart
                                    && new Date(dateStart) > new Date(val)) {
                                    setDateStart(val);
                                }
                            }}
                        />
                        <FontAwesomeIcon icon={faX}
                            className='ms-1'
                            onClick={() => setDateEnd('')}
                        />
                        <FontAwesomeIcon icon={faCopy}
                            className='ms-1'
                            onClick={() => handleCopyInputDateToClipboardClick(dateEnd, true)}
                        />
                    </Col>
                    <Col className='col-sm-1'
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center'
                        }}>
                        {renderButton('acaDailyStatus-refresh',
                            'Reload',
                            () => setReloadCnt(reloadCnt < 100 ? reloadCnt + 1 : 1),
                            loading,
                            undefined,
                            faArrowsRotate
                        )}
                    </Col>
                </Row>
            }
            {
                !loading && data &&
                <React.Fragment>
                    <Row>
                        <Col className='col-sm-3'>
                            <h6 title='New policies and their current state'>
                                {sNewTitle}
                            </h6>
                            <PolicyStateCountTable id='acaDailyStatus-new-policy' rows={creates} stateColLabel='Current State' />
                        </Col>
                        <Col className='col-sm-3'>
                            <h6 title='Existing policies that were updated and their final state'>
                                {sUpdateTitle}
                            </h6>
                            <PolicyStateCountTable id='acaDailyStatus-updated-policy' rows={updates} stateColLabel='Target State' />
                        </Col>
                    </Row>
                    <Row>
                        {
                            users.map((x, idx) => {
                                const { username, newCount, updateCount, stateCreates, stateChanges } = x;

                                return (
                                    <Col className='col-sm-4' key={`acaDailyStatus_col${idx}`}>
                                        <hr />
                                        <h5>
                                            {username}
                                        </h5>
                                        <div>Create: {newCount.map(x => `${x.count} (${x.year})`).join(' | ')}</div>
                                        <div>Update: {updateCount.map(x => `${x.count} (${x.year})`).join(' | ')}</div>
                                        <Row>
                                            <Col>
                                                <h6 title='Policies that the individual created and their initial state'>
                                                    New
                                                </h6>
                                                <PolicyStateCountTable stateColLabel='Initial State'
                                                    key={`acaDailyStatus_colTable${idx}_new`}
                                                    id={`acaDailyStatus-${username}-new`}
                                                    rows={stateCreates} />
                                            </Col>
                                            <Col>
                                                <h6 title='Policies that the individual transitioned to the specified state, including new policies'>
                                                    State Transition (including new)
                                                </h6>
                                                <PolicyStateCountTable stateColLabel='Target State'
                                                    key={`acaDailyStatus_colTable${idx}`}
                                                    id={`acaDailyStatus-${username}`} rows={stateChanges} />
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

function PolicyStateCountTable({ stateColLabel, id, rows }) {

    const columns = [
        {
            text: stateColLabel || 'State',
            dataField: 'state',
            allowFilter: false,
            sort: false
        },
        {
            text: 'Policy Year',
            dataField: 'year',
            allowFilter: false,
            sort: false
        },
        {
            text: 'Count',
            dataField: 'count',
            allowFilter: false,
            sort: false,
        }
    ];
    const name = 'table_' + id;
    return (
        <React.Fragment>
            {
                columns &&
                <ElementTable
                    keyField='state'
                    name={name}
                    key={name + 'Key'}
                    data={rows}
                    disableLoadOnInit
                    pagingOff
                    columns={columns}
                    options={{ noDataText: 'No elements found' }}
                />
            }
        </React.Fragment>
    );
}
