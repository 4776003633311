import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import ComponentModal from '../general/ComponentModal';
import {
    buildInputString, buildInputPhone, buildInputEmail,
    buildInputCheckBox
} from '../utils/EditorInputUtil';
import { fetchWebApi } from '../utils/ClientUtil';
import { displayError, getExceptionMessage } from '../utils/ExceptionUtil';
import * as T from '../utils/TypeUtil';
import '../general/css/general.css';

import {
    EElementType,
    EAppUserField as EFields, AppUserFieldInfos as Fields,
} from './EntityData';
import { validateAppUserData } from './EntityEditorUtil';
import { URL_USER as URL } from '../AppConstants';
import Spinner from 'reactstrap/lib/Spinner';
import BootAlert from '../general/BootAlert';
import { sanitizeJsonObject } from './EntityUtil';
import { LogEntryTable } from './LogEntryTable';
import { TabControl } from '../general/TabControl';

const ETab = {
    LOGS: 'History'
}

/**
 * @param id ID of the user to be displayed
 * @param name [optional] name of the component
 * @param onElementChanged [optional] raised when element data changed, not necessarily saved
 * @param disabled [optional]
 */
export class AppUserEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: {},
            isNew: true,
            activeTabId: ETab.LOGS,
            errors: {},
        };

        this.mount = false;
        this.refAlert = undefined;
        this.refModal = undefined;
        this.refLogTable = undefined;
        this.name = this.props.name || 'editorUser';

        if (T.IsFunc(props.link)) {
            props.link(this);
        }

        this.onInputChanged = this.onInputChanged.bind(this);
        this.validateData = this.validateData.bind(this);
        this.setViewData = this.setViewData.bind(this);
    }

    reload() {
        this.fetchElementData();
    }

    componentWillUnmount() {
        this.mount = false;
    }

    componentDidMount() {
        this.mount = true;
        this.reload();
    }

    fetchElementData() {

        this.setState({ loading: true, isNew: true }, () => {

            const { id } = this.props;
            const isExisting = T.IsDefined(id) && id !== T.EmptyGuid;
            const url = isExisting
                ? `${URL}/${id}`
                : `${URL}/create`;
            const msg = 'Error retrieving user data.';
            fetchWebApi(url)
                .then((result) => {
                    if (!this.mount) return;

                    this.setViewData(result, isExisting, () => {
                        this.setState({ loading: false });
                    });
                })
                .catch((error) => {
                    if (!this.mount) return;
                    getExceptionMessage(msg, error, (errorTxt) => {

                        this.refAlert.Error(msg, errorTxt);
                        this.setState({
                            loading: false,
                            loadError: errorTxt
                        });
                    });
                });
        });
    }

    setViewData(element, isExisting, callback) {

        sanitizeJsonObject(element);

        const data = element || {};

        this.setState({
            isNew: !isExisting,
            data
        }, () => {
            callback();
        });
    }

    validateData(callback) {

        this.setState({ errors: {} }); //Clear all errors

        const { data } = this.state;
        const postData = { ...data };
        const errors = {};

        // Validate data
        validateAppUserData(postData, errors);

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors });
            callback();
            return;
        }
        callback(postData);
    }

    onSave(callback) {

        const { isNew } = this.state;
        this.validateData((postData) => {
            if (!postData) {
                return;
            }

            //POST
            const msg = 'Error saving user data.';
            fetchWebApi(URL,
                {
                    method: 'POST',
                    body: JSON.stringify(postData)
                },
                { 'Content-Type': 'application/json' })
                .then((result) => {
                    if (!this.mount) return;
                    this.setViewData(result, true, () => {

                        const note = isNew
                            ? `Default password: 1st 2-char of ${EFields.UserName} in lowercase + 1st 2-char of ${EFields.UserName} in uppercase + '1!'`
                            : '';
                        this.refModal.Success('Successfully saved.', note,
                            () => {
                                this.refModal.close();
                                if (T.IsFunc(callback)) {
                                    callback(result);
                                }
                            });
                    });
                })
                .catch((error) => {
                    if (!this.mount) return;
                    displayError(this.refModal, msg, error);
                });
        });
    }

    onInputChanged(evt, field, value) {
        const { data } = this.state;
        if (data[field] === value) return;

        const newData = {
            ...data,
            ...{ [field]: value }
        };

        this.setState({
            data: newData
        }, () => {
            const { onElementChanged } = this.props;
            if (T.IsFunc(onElementChanged)) {
                onElementChanged(data);
            }
        });
    }

    renderUserInfo(disabled, isNew) {
        const { data, errors } = this.state;

        const keyPrefix = `${this.name}-`;

        return (
            <React.Fragment>
                <Row className='mb-2'>
                    <Col className='col-sm-3'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.UserName],
                                this.onInputChanged, disabled || !isNew)
                        }
                    </Col>
                    <Col className='col-sm-6'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.Name],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-3'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.NPN],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='col-sm-6'>
                        {
                            buildInputEmail(keyPrefix, data, errors, Fields[EFields.Email],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-4'>
                        {
                            buildInputPhone(keyPrefix, data, errors, Fields[EFields.PhoneNumber],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputCheckBox(keyPrefix, data, errors, Fields[EFields.EmailConfirmed],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    buildTabInfos(disabled, isNew, userId) {

        const tabs = [];

        if (!isNew) {
            tabs.push(
                {
                    id: ETab.LOGS,
                    title: ETab.LOGS,
                    body: (
                        <LogEntryTable parentId={userId} parentType={EElementType.AppUser}
                            name={`${this.name}_logs`}
                            link={(e) => this.refLogTable = e}
                        />
                    )
                });
        }

        return tabs;
    }

    render() {
        const { disabled, } = this.props;
        const { loading, loadError, activeTabId, isNew } = this.state;

        const tabs = this.buildTabInfos(disabled, isNew)

        return (
            <React.Fragment>
                {loading && <Spinner />}
                <ComponentModal link={e => this.refModal = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                {
                    !(loading || loadError) &&
                    this.renderUserInfo(disabled, isNew)
                }
                {
                    T.IsArrayNonEmpty(tabs) &&
                    <Row className='mt-2'>
                        <Col style={{ backgroundColor: 'aliceblue' }}>
                            <TabControl tabInfos={tabs} activeTabId={activeTabId}
                                onTabSelectionChanged={(tabId) => {
                                    this.setState({ activeTabId: tabId });
                                }}
                            />
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    }
}

AppUserEditor.propTypes = {
    name: PropTypes.string,
    link: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onElementChanged: PropTypes.func,
}

