
import React from 'react';
import * as Priv from './PrivilegeUtil';
import * as T from './TypeUtil';

export const GlobalContext = React.createContext();

export const InvalidDate = 'Invalid date';

export const MAX_IMAGE_FILE_SIZE_MB = .5;
export const BYTES_PER_MB = 1000000;

export const HexColors = {
    // this is the hex that matches a gray element that has the disabled attribute
    DisabledGray: '#9FA5AA',
};

// Function to return any global objects to be used in the GlobalContext.
export function GetGlobalContext() {
    return {
        //hubRegistry: new HubRegistry(),
        USING: {
            USER_PRIVILEGES: (callback) => {
                if (T.IsDefined(globalContext()._USING_CACHE._USER_PRIVILEGES)) {
                    if (T.IsFunc(callback)) { callback(globalContext()._USING_CACHE._USER_PRIVILEGES); }
                }
                else {
                    Priv.GetUserPrivileges((privileges) => {
                        globalContext()._USING_CACHE._USER_PRIVILEGES = privileges;
                        if (T.IsFunc(callback)) {
                            callback(globalContext()._USING_CACHE._USER_PRIVILEGES);
                        }
                    });
                }
            },
            NMS_SETTINGS: () => {
                if (T.IsDefined(globalContext()._USING_CACHE._SYSTEM_SETTINGS)) {
                    return globalContext()._USING_CACHE._SYSTEM_SETTINGS;
                }

                /* IN PROGRESS (Intend to cache this globally so it only has to be fetched once on page init)
                    FetchUtil.getRequestDefaults().then((settings) => {
                        globalContext()._USING_CACHE._SYSTEM_SETTINGS = settings;
                        return globalContext()._USING_CACHE._SYSTEM_SETTINGS;
                    });
                    */
            }
        },
        _USING_CACHE:
        {
            _USER_PRIVILEGES: undefined,
            _SYSTEM_SETTINGS: undefined
        }
    };
}

// Get the current global context object saved in the browser tab session.
export function globalContext() {
    if (!T.IsDefined(window._globalContext)) { window._globalContext = GetGlobalContext(); }

    return window._globalContext;
}
