import React, { Component } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';

import BootAlert from './BootAlert';
import Spinner from './Spinner';
import * as T from '../utils/TypeUtil';
import { v4 as uuid } from 'uuid';
import { EColor } from '../utils/DataFormatUtil';
import { ETextEditorMode, TextEditor } from './TextEditor';
import { NormalizeTextEditorValue } from '../user/EntityData';

/**
 * @param ref [optional]
 * @param cname [optional]
 * @param size [optional] Default is 'md'
 * @param style [optional]
 * @param okDisabled [optional]
 * @param onClose [optional]
 */
export default class ComponentModal extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.getButton = this.getButton.bind(this);

        const { size, cname, onClose, style, link } = this.props;

        // Setting initial state.
        this.state = {
            open: false,
            title: 'Notification',
            body: 'Component loaded.',
            ok: 'Confirm',
            okColor: EColor.Primary,
            cancel: 'Cancel',
            cancelColor: EColor.Secondary,
            okClick: false,
            cancelClick: false,
            generateSpinner: false,
            size: size || 'md',
            cname: cname || '',
            style: style,
            onClose: T.IsFunc(onClose) ? onClose : false,
            key: '',
            okEnableCtrl: false,
        };

        if (T.IsFunc(link)) {
            link(this);
        }
    }

    // This state creates an alert modal with a
    // message and an Ok button to close it.
    Alert(message, note = '', confirmFunction = false, success = false, title = '') {
        this.setState(
            {
                open: true,
                title: title || ((success) ? 'Success' : 'Alert'),
                body: (
                    <BootAlert
                        key="modal_alert_info"
                        canToggle={false}
                        icon={(success) ? 'success' : 'info'}
                        visible={true}
                        message={message}
                        footer={note}
                        color={(success) ? EColor.Success : EColor.Info}
                    />
                ),
                ok: 'OK',
                okColor: EColor.Primary,
                cancel: '',
                okClick: confirmFunction,
                cancelColor: EColor.Secondary,
                // In the case of a Success modal, clicking the X will use
                // any logic from the confirm function
                cancelClick: (success) ? confirmFunction : false,
                generateSpinner: false,
                additionalButtons: [],
                key: uuid()
            }
        );
    }

    // Shortcut function to a success alert.
    Success(message, note = '', confirmFunction = false) { this.Alert(message, note, confirmFunction, true); }

    /**
     * Displays an error message allowing the error icon and severity
     * to be specified along with an error message.
     * @param {string} message message to be displayed
     * @param {*} note message footer
     * @param {boolean} warning whether this is warning rather than an error
     * @param {*} onClosed Func<> or 'false'
     */
    Error(message, note, warning = false, onClosed = false) {
        const type = (warning) ? 'warning' : 'error';

        const closeFunction = () => {
            this.close();
            if (T.IsFunc(onClosed)) {
                onClosed();
            }
        };

        this.setState(
            {
                open: true,
                title: (warning) ? 'Warning' : 'Error',
                body: (
                    <BootAlert
                        key={`modal_alert_${type}`}
                        canToggle={false}
                        icon={type}
                        visible={true}
                        message={message}
                        footer={note}
                        color={(warning) ? EColor.Warning : EColor.Danger}
                    />
                ),
                ok: 'OK',
                okColor: EColor.Primary,
                okClick: closeFunction,
                cancel: '',
                cancelColor: EColor.Secondary,
                cancelClick: closeFunction,
                generateSpinner: false,
                additionalButtons: [],
                key: uuid()
            }
        );
    }

    // Shortcut function to a warning error.
    Warning(message, note, onClosed) { this.Error(message, note, true, onClosed); }

    /**
     * Display a confirmation message
     * @param {string} message 
     * @param {string} confirmButton name of the confirmation button
     * @param {*} confirmFunction action to be executed when the confirmation button is clicked
     * @param {*} cancelFunction action to be executed when the cancelation button is clicked
     * @param {*} note message footer
     * @param {string} cancelButton name of the cancelationbutton
     * @param {*} appendElements
     */
    Confirm(message, note = '', confirmFunction, cancelFunction = false,
        confirmButton = 'OK', cancelButton = 'Cancel', appendElements = []) {
        this.setState({
            open: true,
            title: 'Confirm',
            body: (
                <React.Fragment>
                    <BootAlert
                        key="modal_alert_question"
                        canToggle={false}
                        icon="question"
                        visible={true}
                        message={message}
                        footer={note}
                        color={EColor.Primary}
                    />
                    {appendElements}
                </React.Fragment>
            ),
            ok: confirmButton,
            okColor: EColor.Primary,
            okClick: confirmFunction,
            cancel: cancelButton,
            cancelColor: EColor.Secondary,
            cancelClick: cancelFunction,
            additionalButtons: [],
            key: uuid()
        });
    }

    /**
     * Display a confirmation message
     * @param {string} message 
     * @param {string} dangerButton name of the danger button
     * @param {*} dangerFunction action to be executed when the danger button is clicked
     * @param {*} cancelFunction action to be executed when the cancelation button is clicked
     * @param {*} note message footer
     * @param {string} cancelButton name of the cancelationbutton
     * @param {*} appendElements
     */
    ConfirmDanger(message, note = '', dangerFunction, cancelFunction = false,
        dangerButton = 'OK', cancelButton = 'Cancel', appendElements = []) {
        this.setState({
            open: true,
            title: 'Warning',
            body: (
                <React.Fragment>
                    <BootAlert
                        key="modal_alert_danger"
                        canToggle={false}
                        icon="warning"
                        visible={true}
                        message={message}
                        footer={note}
                        color={EColor.Warning}
                    />
                    {appendElements}
                </React.Fragment>
            ),
            ok: dangerButton,
            okColor: EColor.Danger,
            okClick: dangerFunction,
            cancel: cancelButton,
            cancelColor: EColor.Secondary,
            cancelClick: cancelFunction,
            generateSpinner: false,
            additionalButtons: [],
            key: uuid()
        });
    }


    /**
     * This state allows for setting a more configurable modal,
     * sets the ok and cancel buttons to primary and secondary styles
     * allows the ok and cancel buttons to have their name and callback functions set
     * @param {string} title dialog title
     * @param {*} body body component
     * @param {*} okFunction funtion to be executed when OK button is clicked
     * @param {*} cancelFunction  funtion to be executed when cancelation button is clicked
     * @param {string} apply [optional] name of the applying button. If set, the okFunction will be invoked with a 'false' input parameter
     * @param {string} ok name of the OK button
     * @param {string} cancel name of the Cancel button
     * @param {string} apply name of the Apply button
     * @param {*} additionalButtons [optional] additional buttons to be displayed
     * @param {boolean} okEnableCtrl whether to enable the OK button
     */
    ComponentDialog(title, body, okFunction, cancelFunction, apply = undefined,
        ok = 'OK', cancel = 'Cancel',
        additionalButtons = [], okEnableCtrl = false) {
        this.setState({
            open: true,
            title,
            body,
            ok,
            okColor: EColor.Primary,
            okClick: okFunction,
            cancel,
            cancelColor: EColor.Secondary,
            cancelClick: cancelFunction,
            additionalButtons,
            apply,
            okEnableCtrl
        });
    }

    InputTextDialog(title, initialValue, label, onInputValueChanged,
        okFunction, cancelFunction,
        { mode, inputMaxLen, inputRequired = false, inputUnit = undefined },
        ok = 'OK', cancel = 'Cancel', apply = undefined, okEnableCtrl = false) {

        let linkModal = undefined;
        let val = initialValue;
        let valEmpty = true;
        const body = (
            <React.Fragment>
                <ComponentModal link={(e) => linkModal = e} />
                <TextEditor label={label}
                    value={initialValue}
                    mode={mode}
                    keyPrefix='modal-input-text'
                    required={inputRequired}
                    unit={inputUnit}
                    length={inputMaxLen}
                    lineCnt={5}
                    useValueFromInputChanged={true}
                    onInputChanged={(evt, field, fieldVal, isEmpty) => {
                        valEmpty = isEmpty;
                        val = isEmpty ? undefined : NormalizeTextEditorValue(fieldVal);
                        onInputValueChanged(val);
                    }}
                />
            </React.Fragment>
        );
        this.setState({
            open: true,
            title,
            body: body,
            ok,
            okColor: EColor.Primary,
            okClick: (isApply) => {
                if (inputRequired) {
                    if (mode === ETextEditorMode.RICHTEXT) {
                        if (valEmpty) {
                            linkModal.Error(`${label} cannot be empty`);
                            return;
                        }
                    }
                    else if (!val || val.trim().length === 0) {
                        linkModal.Error(`${label} cannot be empty`);
                        return;
                    }
                }
                if (T.IsFunc(okFunction)) {
                    okFunction(isApply);
                }
            },
            cancel,
            cancelColor: EColor.Secondary,
            cancelClick: cancelFunction,
            apply,
            okEnableCtrl
        });
    }

    // Convenience function for showing a loading modal.
    Loading(title = 'Loading...') { this.ComponentDialog(title, <Spinner />, false, false, '', ''); }


    // Toggle the modal
    toggle(cancel = false) {
        const { open, cancelClick, onClose } = this.state;
        this.setState({ open: !open });

        if (cancel && T.IsFunc(cancelClick)) {
            cancelClick();
        }
        if (T.IsFunc(onClose)) {
            onClose();
        }
    }

    close(cancel = false) {
        this.setState({ open: false }, () => {
            const { cancelClick, onClose } = this.state;

            if (cancel && T.IsFunc(cancelClick)) {
                cancelClick();
            }
            if (T.IsFunc(onClose)) {
                onClose();
            }
        });
    }

    open() {
        this.setState({ open: true });
    }

    /// Used to generate the modals buttons
    // If a stateName is not given nothing is returned
    // If callback is set to false, the button click will default to the toggle function
    getButton(stateName, color, callback, disabled = false) {
        if (typeof (stateName) !== 'undefined' && stateName !== '') {

            const handleClick = () => {
                if (T.IsFunc(callback)) callback();
                else this.toggle();
            };
            return (<Button color={color} onClick={handleClick} disabled={disabled}>{stateName}</Button>);
        }
    }

    render() {
        const {
            key, title,
            style, size, cname,
            open,
            body: modalBody,
            ok, okColor, okClick, okEnableCtrl,
            cancel, cancelColor, cancelClick,
            apply,
            additionalButtons,
            generateSpinner
        } = this.state;

        // We check to see if the body is either a set in stone object or a
        // function. This allows for us to have a dynamic body (i.e. one that responds to state changes)
        // instead of one that is more static.
        const body = T.IsFunc(modalBody) ? modalBody() : modalBody;
        const clazz = `modal-dialog-scrollable ${cname || ''}`;

        // Currently supports 2 main buttons, with additional buttons that can be placed in between them
        return (
            <div key={key} onMouseDown={(e) => e.stopPropagation()}>
                <Modal className={clazz} size={style ? null : size} isOpen={open} style={style}>
                    <ModalHeader toggle={() => { this.toggle(true); }}>{title}</ModalHeader>
                    <ModalBody>
                        {generateSpinner ? <Spinner /> : body}
                    </ModalBody>
                    <ModalFooter>
                        {this.getButton(ok, okColor, okClick, okEnableCtrl)}{' '}
                        {additionalButtons}
                        {this.getButton(cancel, cancelColor, cancelClick)}{' '}
                        {
                            !T.stringIsNullOrEmpty(apply) &&
                            T.IsFunc(okClick) &&
                            this.getButton(apply, EColor.Primary, () => { okClick(true) }, okEnableCtrl)
                        }
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
