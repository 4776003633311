import { CONTENT_TYPE_JSON, fetchCurrentUser, fetchWebApi } from "../utils/ClientUtil";
import * as T from "../utils/TypeUtil";
import {
    URL_ADDRESS, URL_LOG, URL_PERSON, URL_POLICY, URL_SETTING, URL_TASK, URL_USER, URL_VIEW_SETTINGS
} from '../AppConstants';
import { displayError, getExceptionMessage } from "../utils/ExceptionUtil";
import { ToUTCString } from "../utils/DataFormatUtil";

export const fetchCityState = (zip5, callback) => {
    if (!T.IsDefined(zip5)) return;
    zip5 = zip5.trim();
    if (zip5.length !== 5) return;

    const url = `${URL_ADDRESS}/cityStateCounty?zip=${zip5}`;

    fetchWebApi(url)
        .then((result) => {
            if (result) {
                sanitizeJsonObject(result);
                const {
                    Item1: city,
                    Item2: state,
                    Item3: county
                } = result;
                callback(true, city || undefined, state || undefined, county);
            }
        })
        .catch((error) => {
            callback();
        });
};

export const sanitizeJsonObject = (e) => {

    if (!T.IsDefined(e)) return;

    if (T.IsArrayNonEmpty(e)) {
        for (const i of e) {
            if (T.IsObject(i)) {
                sanitizeJsonObject(i);
            }
        }
    }
    else if (T.IsObject(e)) {

        delete e['$id'];
        Object.values(e).forEach((val) => {
            sanitizeJsonObject(val);
        });
    }
}

/**
 * @param {*} id ID of the Person to retrieve the data for
 * @param {*} callback Func<in personId, callback(in Person, in errorMsg, in error)
 */
export const fetchPerson = (id, callback) => {

    const url = `${URL_PERSON}/${id}`;

    const msg = 'Error retrieving individual data';
    fetchWebApi(url)
        .then((result) => {
            sanitizeJsonObject(result);
            callback(result);
        })
        .catch((error) => {
            callback([], msg, error);
        });

}

export const fetchUserPrivileges = (username, callback) => {

    const url = `${URL_USER}/privileges?username=${username || ''}`;

    const msg = 'Error retrieving user privileges';
    fetchWebApi(url)
        .then((result) => {
            sanitizeJsonObject(result);
            const privilegeMap = {};
            result.forEach(x => {
                privilegeMap[x] = true;
            });
            callback(privilegeMap);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback([], errorTxt);
            });
        });
};

export const fetchLogins = (role, callback) => {

    const msg = 'Error retrieving available login accounts.';
    fetchWebApi(`${URL_USER}/list?role=${T.IsDefined(role) ? role : ''}`)
        .then((result) => {
            sanitizeJsonObject(result);
            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback([], errorTxt);
            });
        });
}

export const formatLogins = (appUsers, includeNPN) => {

    return appUsers.map((x) => {
        const npn = includeNPN && x.NPN ? ` | ${x.NPN}` : '';
        return {
            Id: x.Id,
            Name: `${x.Name} [${x.UserName}${npn}]`
        };
    });
}

export const fetchLoginsWithPrivileges = (privileges, callback) => {

    const msg = 'Error retrieving login by privileges.';
    fetchWebApi(`${URL_USER}/list`,
        {
            method: 'POST',
            body: JSON.stringify(privileges)
        },
        { 'Content-Type': CONTENT_TYPE_JSON })
        .then((result) => {
            sanitizeJsonObject(result);

            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback({}, errorTxt);
            });
        });
}

export const fetchLoginsWithNPNs = (callback) => {

    const msg = 'Error retrieving available users with NPN.';
    fetchWebApi(`${URL_USER}/listWithNPNs`)
        .then((result) => {
            sanitizeJsonObject(result);
            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback([], errorTxt);
            });
        });
}

export const fetchAvailableNPNs = (callback) => {

    const msg = 'Error retrieving available NPNs.';
    fetchWebApi(`${URL_USER}/npns`)
        .then((result) => {
            sanitizeJsonObject(result);
            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback([], errorTxt);
            });
        });
}

export const fetchPolicyTypes = (policyCategory, callback) => {

    const url = `${URL_POLICY}/type/list?category=${policyCategory || ''}`;

    const msg = 'Error retrieving available policy types';
    fetchWebApi(url)
        .then((result) => {
            sanitizeJsonObject(result);
            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback([], errorTxt);
            });
        });
};

export const fetchCarrierOptions = (policyType, callback) => {

    const url = `${URL_POLICY}/carrier/list?type=${policyType || ''}`;

    const msg = 'Error retrieving available carriers';
    fetchWebApi(url)
        .then((result) => {
            sanitizeJsonObject(result);
            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback([], errorTxt);
            });
        });
};

export const fetchRelatedUsers = (userId, callback) => {

    const url = `${URL_PERSON}/${userId}/related`;

    const msg = 'Error retrieving related individuals';
    fetchWebApi(url,
        {
            'Content-Type': CONTENT_TYPE_JSON,
        })
        .then((result) => {
            sanitizeJsonObject(result);
            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback([], errorTxt);
            });
        });
}

export const saveUserRels = (refModal, userId, rels, callback) => {

    const msg = 'Error saving relationship(s)';
    fetchWebApi(`${URL_PERSON}/${userId}/related`,
        {
            method: 'POST',
            body: JSON.stringify(rels)
        },
        { 'Content-Type': CONTENT_TYPE_JSON })
        .then((result) => {
            sanitizeJsonObject(result);
            refModal.Success('Successfully saved relationship(s).', '', () => {
                refModal.close();
                callback(true);
            });
        })
        .catch((error) => {
            displayError(refModal, msg, error, () => {
                callback();
            });
        });
}

export const deleteUserRel = (refModal, userId, relatedUserId, callback) => {

    const msg = 'Error deleting relationship';
    fetchWebApi(`${URL_PERSON}/${userId}/related/${relatedUserId}`,
        {
            method: 'DELETE'
        })
        .then((result) => {
            refModal.Success('Successfully deleted relationship.', '', () => {
                refModal.close();
                callback(true);
            });
        })
        .catch((error) => {
            displayError(refModal, msg, error, () => {
                callback();
            });
        });
}

export const updateUserRel = (refModal, userId, relatedUserId, relType, callback) => {

    const msg = 'Error updating relationship';
    fetchWebApi(`${URL_PERSON}/${userId}/related/${relatedUserId}?type=${relType}`,
        {
            method: 'PUT'
        })
        .then((result) => {
            refModal.Success('Successfully updated relationship.', '', () => {
                refModal.close();
                if (T.IsFunc(callback)) {
                    callback(true);
                }
            });
        })
        .catch((error) => {
            displayError(refModal, msg, error, () => {
                if (T.IsFunc(callback)) {
                    callback();
                }
            });
        });
}


export const saveTask = (refModal, task, note, callback = undefined) => {

    const body = {
        Task: task,
        Note: T.IsDefined(note) && note.trim().length > 0 ? note.trim() : undefined
    };

    const msg = 'Error saving task data.';
    fetchWebApi(URL_TASK,
        {
            method: 'POST',
            body: JSON.stringify(body)
        },
        { 'Content-Type': CONTENT_TYPE_JSON })
        .then((result) => {
            refModal.Success('Successfully saved.', '',
                () => {
                    if (T.IsFunc(callback)) {
                        callback(result);
                    }
                    refModal.close();
                });
        })
        .catch((error) => {
            displayError(refModal, msg, error, () => {
                if (T.IsFunc(callback)) {
                    callback(undefined);
                }
            });
        });
}

/**
 * 
 * @param {*} id 
 * @param {import("../AppConstants").IAppStringHandler} callback 
 */
export const fetchUserRole = (id, callback) => {

    const msg = 'Error retrieving user data';
    fetchWebApi(`${URL_USER}/${id}/role`)
        .then((result) => {
            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (errorTxt) => {
                callback(undefined, errorTxt);
            });
        });
}

export const saveUserRole = (refModal, userId, role, secret, callback) => {

    const sAction = T.stringIsNullOrEmpty(secret)
        ? 'updating role'
        : 'executing request';
    fetchWebApi(`${URL_USER}/role`,
        {
            method: 'POST',
            body: JSON.stringify({
                Role: role,
                UserId: userId,
                Secret: secret
            })
        },
        { 'Content-Type': CONTENT_TYPE_JSON })
        .then(() => {
            refModal.Success(`Successfully ${sAction}`, '', callback);
        })
        .catch((error) => {
            displayError(refModal, `Error ${sAction}`, error,
                () => {
                    callback(true);
                });
        });
}
export const saveUserPwd = (refModal, userId, secret, callback) => {

    fetchWebApi(`${URL_USER}/${userId}/pwd`,
        {
            method: 'POST',
            body: JSON.stringify({
                UserId: userId,
                Secret: secret
            })
        },
        { 'Content-Type': CONTENT_TYPE_JSON })
        .then(() => {
            refModal.Success(`Successfully saved  user password`, '', callback);
        })
        .catch((error) => {
            displayError(refModal, `Error saving user password`, error,
                () => {
                    callback(true);
                });
        });
}

export const fetchRenewPolicyDate = (refModal, policyId, callback) => {
    fetchWebApi(`${URL_POLICY}/${policyId}/renewDate`)
        .then((result) => {
            callback(true, result);
        })
        .catch((error) => {
            displayError(refModal, `Error checking policy renewal date`, error,
                () => {
                    callback(false);
                });
        });
}

export const fetchAppSettings = (onComplete, onError) => {
    fetchWebApi(`${URL_SETTING}/list`,
        {
            'Content-Type': CONTENT_TYPE_JSON,
        })
        .then((result) => {
            let data = {};
            result.forEach(item => {
                const { Id, Value } = item;
                data = {
                    ...data,
                    ...{ [Id]: Value }
                };
            });

            onComplete(data);
        })
        .catch((error) => {
            onError(error);
        });
}

export const saveAppSettings = (data, onComplete, onError) => {
    const items = [];
    Object.keys(data).forEach((name) => {
        items.push({
            Id: name,
            Value: data[name]
        });
    })

    fetchWebApi(`${URL_SETTING}`,
        {
            method: 'POST',
            body: JSON.stringify(items)
        },
        { 'Content-Type': CONTENT_TYPE_JSON })
        .then((result) => {
            onComplete(result);
        })
        .catch((error) => {
            onError(error);
        });
}

export const fetchAppInfo = (onComplete, onError) => {
    fetchWebApi(`${URL_SETTING}/info`,
        {
            'Content-Type': CONTENT_TYPE_JSON,
        })
        .then((result) => {
            sanitizeJsonObject(result);
            onComplete(result);
        })
        .catch((error) => {
            getExceptionMessage('Error fetching application info', error, onError);
        });
}

export const fetchViewSettings = (type, onComplete) => {

    const url = `${URL_VIEW_SETTINGS}/list?type=${type || ''}`;
    fetchWebApi(url)
        .then(result => {
            sanitizeJsonObject(result);
            onComplete(result);
        })
        .catch(error => {
            getExceptionMessage('Error loading view settings', error, (msg) => {
                onComplete(undefined, msg);
            })
        });
}

export const saveViewSettings = (data, onComplete, onError) => {

    fetchCurrentUser()
        .then((user) => {
            const { loginUserId } = user || {};
            if (!data.CreatedById) {
                data.CreatedById = loginUserId;
            }

            fetchWebApi(`${URL_VIEW_SETTINGS}`,
                {
                    method: 'POST',
                    body: JSON.stringify(data)
                },
                { 'Content-Type': CONTENT_TYPE_JSON })
                .then((result) => {
                    sanitizeJsonObject(result);
                    onComplete(result);
                })
                .catch((error) => {
                    onError(error);
                });
        })
        .catch((error) => {
            onError(error);
        });
}

export const fetchAcaDailyStatus = (policyYear, dateStart, dateEnd, callback) => {

    const sDateStart = ToUTCString(dateStart);
    const sDateEnd = ToUTCString(dateEnd);
    const urlParams = [
        `policyYear=${policyYear || ''}`,
        `start=${sDateStart}`,
        `end=${sDateEnd}`
    ];
    const url = `${URL_LOG}/acaActivityStatus?${urlParams.join('&')}`;

    const msg = 'Error retrieving daily status';
    fetchWebApi(url)
        .then((result) => {
            sanitizeJsonObject(result);
            callback(result);
        })
        .catch((error) => {
            getExceptionMessage(msg, error, (sError) => {
                callback(undefined, sError);
            })
        });

}