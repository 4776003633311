import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import * as T from '../utils/TypeUtil';

export const ElementTableColumnToggleList = ({
    columns,
    onColumnToggle: onToggle,
    toggles: propToggles,
    defaultToggles,
    onChange }) => {
    const cols = columns.filter(x => !x.isDummyField && !x.alwaysHidden);
    const [toggles, setToggles] = useState({ ...propToggles });

    useEffect(() => {
        if (T.IsFunc(onChange)) {
            onChange(toggles);
        }
    }, [onChange, toggles]);

    const onColumnToggle = (dataField) => {

        const updated = { ...toggles };
        onToggle(dataField);
        updated[dataField] = !updated[dataField];

        setToggles(updated);
    }
    const onToggleAll = (active) => {

        const updated = { ...toggles };
        cols.forEach(column => {
            if (toggles[column.dataField] === active) return;

            onToggle(column.dataField);
            updated[column.dataField] = !updated[column.dataField];
        });
        setToggles(updated);
    }
    const onReset = () => {

        const updated = { ...toggles };
        cols.forEach(column => {
            const visible = defaultToggles[column.dataField];
            if ((column.hidden && visible)
                || !visible) {
                onToggle(column.dataField);
                updated[column.dataField] = !updated[column.dataField];
            }
            setToggles(updated);
        });
    }

    return (
        <Row>
            <Col className="btn-group btn-group-xs btn-group-toggle btn-group-vertical"
                role='group'
                data-toggle="buttons">
                {
                    cols.map(column => ({
                        ...column,
                        toggle: toggles[column.dataField]
                    }))
                        .map(column => (
                            <button key={column.dataField}
                                type='button'
                                className={`btn btn-sm btn-info ${column.toggle ? 'active' : ''}`}
                                data-toggle='button'
                                aria-pressed={column.toggle ? 'true' : 'false'}
                                onClick={() => onColumnToggle(column.dataField)}
                            >
                                {column.text || column.dataField}
                            </button>
                        ))
                }
            </Col>
            <Col className="" >
                <span size='sm' key={'showAll'} className={`btn btn-primary mr-1 me-1 mb-1`}
                    onClick={() => {
                        onToggleAll(true);
                    }} >Show All</span>
                <span size='sm' key={'hideAll'} className={`btn btn-primary mr-1 me-1 mb-1`}
                    onClick={() => {
                        onToggleAll(false);
                    }} >Hide All</span>
                <span size='sm' key={'reset'} className={`btn btn-primary`}
                    onClick={() => {
                        onReset();
                    }} >Reset</span>
            </Col>
        </Row>)
};