import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import ComponentModal from '../general/ComponentModal';
import {
    buildInputString
} from '../utils/EditorInputUtil';
import { fetchWebApi } from '../utils/ClientUtil';
import { displayError, getExceptionMessage } from '../utils/ExceptionUtil';
import * as T from '../utils/TypeUtil';
import '../general/css/general.css';

import {
    EElementField as EFields,
    EElementFieldInfos as Fields,
} from './EntityData';
import { validateElementData } from './EntityEditorUtil';
import Spinner from 'reactstrap/lib/Spinner';
import BootAlert from '../general/BootAlert';
import { EColor } from '../utils/DataFormatUtil';
import { sanitizeJsonObject } from './EntityUtil';

/**
 * @param id ID of the user to be displayed
 * @param name [optional] name of the component
 * @param url 
 * @param link 
 * @param disabled [optional]
 */
export class ElementEditor extends Component {
    constructor(props) {
        super(props);
        const {
            id: inId,
            name: inName,
            link: inLink } = props;

        this.state = {
            id: inId,
            loading: true,
            loadError: undefined,
            data: undefined,
            errors: {}
        };
        this.name = inName || 'editorElement';

        this.dlg = undefined;

        this.reload = this.reload.bind(this);
        this.fetchElementData = this.fetchElementData.bind(this);
        this.handleInputChanged = this.handleInputChanged.bind(this);
        this.validateData = this.validateData.bind(this);
        this.onSave = this.onSave.bind(this);

        if (T.IsFunc(inLink)) {
            inLink(this);
        }
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        this.fetchElementData();
    }

    fetchElementData = () => {
        const { url } = this.props;
        const { id } = this.state;

        this.setState({ loading: true, loadError: undefined },
            () => {
                if (T.stringIsNullOrEmpty(id)) {

                    this.setState({
                        loading: false,
                        data: { Id: '', Name: '' }
                    });
                    return;
                }

                const msg = 'Error retrieving carrier information.';
                fetchWebApi(`${url}/${id}`)
                    .then((result) => {
                        sanitizeJsonObject(result);

                        this.setState({
                            loading: false,
                            data: result
                        });
                    })
                    .catch((error) => {
                        getExceptionMessage(msg, error, (errorTxt) => {
                            this.setState({
                                loading: false,
                                loadError: errorTxt
                            });
                        });
                    });
            });
    }

    validateData = (callback) => {
        const { data } = this.state;

        this.setState({ errors: {} }, //Clear all errors
            () => {
                const postData = { ...data };
                const checkErrors = {};

                // Validate data
                validateElementData(postData, checkErrors);

                if (Object.keys(checkErrors).length > 0) {
                    this.setState({ errors: checkErrors }, callback);
                    return;
                }
                callback(postData);
            });

    }

    onSave(callback) {
        const { url } = this.props;

        this.dlg.Loading('Validating view data...');
        this.validateData((postData) => {
            if (!postData) {
                this.dlg.close();
                return;
            }
            this.dlg.Loading('Saving view data...');

            const eId = postData[EFields.Id];

            //POST
            const msg = 'Error saving carrier data.';
            fetchWebApi(url,
                {
                    method: 'POST',
                    body: JSON.stringify(postData)
                },
                { 'Content-Type': 'application/json' })
                .then((result) => {

                    this.setState({
                        data: result,
                        id: eId
                    }, () => {
                        this.dlg.Success('Successfully saved.', '',
                            () => {
                                if (T.IsDefined(callback)) {
                                    callback();
                                }
                                this.dlg.close();
                            });
                    })
                })
                .catch((error) => {
                    displayError(this.dlg, msg, error);
                });
        });
    }

    handleInputChanged = (evt, field, value) => {
        const { data } = this.state;
        if (data[field] === value) return;

        const newData = {
            ...data,
            ...{ [field]: value }
        };
        this.setState({ data: newData });
    }

    render() {
        const { disabled } = this.props;
        const { loading, loadError, id, data, errors } = this.state;

        const keyPrefix = `${this.name}-`;
        return (
            <React.Fragment>
                {loading && <Spinner />}
                <ComponentModal link={e => this.dlg = e} />
                {
                    loadError &&
                    <BootAlert canToggle={false} icon="danger" visible={true} message={loadError} color={EColor.Danger} />
                }
                {
                    !loading && !loadError &&
                    <React.Fragment>
                        <Row className='mb-2'>
                            <Col>
                                {
                                    buildInputString(keyPrefix, data, errors, Fields[EFields.Id],
                                        this.handleInputChanged, disabled || T.IsDefined(id))
                                }
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col>
                                {
                                    buildInputString(keyPrefix, data, errors, Fields[EFields.Name],
                                        this.handleInputChanged, disabled)
                                }
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

ElementEditor.propTypes = {
    name: PropTypes.string,
    link: PropTypes.func,
    id: PropTypes.string,
    url: PropTypes.string,
    disabled: PropTypes.bool,
}

