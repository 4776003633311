import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    ElementTablePageOptions,
    EElementTableColFilterType,
    TableColSortEntry
} from '../general/ElementTableUtil';
import ComponentModal from '../general/ComponentModal';
import * as T from '../utils/TypeUtil';

import {
    EElementField as EFields,
    EElementFieldLabel as EFieldLabels
} from './EntityData';
import { renderOptionsCell, displayElementEditor, displayFileUploadDialog } from './EntityEditorUtil';
import '../general/css/general.css';

import { faEdit, faFileImport, faTrash } from '@fortawesome/free-solid-svg-icons';
import { URL_CARRIER as URL } from '../AppConstants';
import BootAlert from '../general/BootAlert';
import { renderButton } from '../utils/EditorInputUtil';

/**
 * @param loginUserId ID of the login user
 * @param name [optional] component name
 * @param sizePerPage [optional] number of rows per page
 * @param selectRowOptions [optional]
 * @param disableLoadOnInit [optional]
 */
export class CarrierTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refModal = undefined;
        this.refAlert = undefined;
        this.refTable = undefined;

        this.renderOptions = this.renderOptions.bind(this);
        this.handleFormatUrl = this.handleFormatUrl.bind(this);
        this.handleNewEditButtonClick = this.handleNewEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleImportBtnClicked = this.handleImportBtnClicked.bind(this);

        this.columns = this.getColumns();

        const { name, id, sizePerPage } = this.props;
        this.name = name || 'tableCarrier';

        this.state = {
            dataId: id,
            data: [],
            pageOptions: new ElementTablePageOptions(false, sizePerPage),
            defaultSorted: [
                new TableColSortEntry(EFields.Id),
            ],
        };

        if (T.IsFunc(props.link)) {
            props.link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {
        const { sizePerPage } = this.props;

        if (prevProps.sizePerPage !== sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            this.setState({ pageOptions: updatedPageOptions });
        }
    }

    getColumns() {

        const cols = [
            {
                text: EFieldLabels.Id,
                dataField: EFields.Id,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFieldLabels.Name,
                dataField: EFields.Name,
                filter: EElementTableColFilterType.TEXT,
                headerStyle: { width: '60%' },
            },
            {
                text: '',
                dataField: 'options',
                isDummyField: true,
                sort: false,
                allowFilter: false,
                formatter: (cellVal, row) => {
                    return this.renderOptions(row);
                },
            },
        ];

        return cols;
    }

    handleNewEditButtonClick(e) {

        const isEdit = T.IsDefined(e);

        displayElementEditor(this.refModal, 'Carrier', URL, isEdit ? e.Id : undefined,
            (updated) => {
                if (!updated) return;

                //reload the table in case changes affect sorting/filter
                this.refTable.reloadTable();
            }
        );
    }

    handleDeleteButtonClick() {
        this.refModal.Alert('Operation is not yet supported');
    }

    handleImportBtnClicked() {
        const url = `${URL}/import`;

        displayFileUploadDialog('Import Carrier(s)', this.refModal, url,
            () => {
                if (!this.mount) return;

                //reload the table in case changes affect sorting/filter
                this.reloadTable();
            },
            '.csv',
            (result) => `Import count: ${result}`
        );
    }

    handleFormatUrl(page, pageSize, searchText, postData) {
        this.refAlert.hide();

        const urlParams = [
            `page=${page}`,
            `pageSize=${pageSize}`,
            `filter=${searchText || ''}`
        ];
        return `${URL}/paged?${urlParams.join('&')}`;
    }

    renderOptions(element) {
        const options = [
            {
                name: 'Edit',
                onClick: () => this.handleNewEditButtonClick(element),
                faIcon: faEdit
            },
            {
                name: 'Delete',
                onClick: () => this.handleDeleteButtonClick(element),
                faIcon: faTrash,
                disabled: true
            }
        ];
        return renderOptionsCell(`${this.name}-${element.Id}`, options);
    }

    render() {
        const { selectRowOptions } = this.props;
        const { data, pageOptions, defaultSorted } = this.state;
        const sId = `${this.name}_eTable`;

        const buttons = [
            renderButton(`${this.name}-import`, 'Import',
                this.handleImportBtnClicked,
                false,
                'd-inline-block mr-1 me-1', faFileImport)
        ];


        return (
            <React.Fragment>
                <ComponentModal size='lg' link={e => this.refModal = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <span>{this.name}</span>
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={data}
                    disableLoadOnInit={this.props.disableLoadOnInit}
                    link={(c) => { this.refTable = c; }}
                    columns={this.columns}
                    buttons={buttons}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                    selectRowOptions={selectRowOptions}
                    onFormatUrl={this.handleFormatUrl}
                    onNew={this.handleNewEditButtonClick}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
