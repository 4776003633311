import React, { useEffect, useRef, useState } from 'react';
import {
    buildFormFeedback,
    buildInputLabel,
} from '../utils/EditorInputUtil';
import * as T from '../utils/TypeUtil';
import '../general/css/general.css';

import {
    RichTextEditorComponent, Inject, Toolbar, HtmlEditor,
    Link, QuickToolbar,
    Image,
    Table,
    Count,
} from '@syncfusion/ej2-react-richtexteditor';
import ComponentModal from './ComponentModal';
import { BYTES_PER_MB, MAX_IMAGE_FILE_SIZE_MB } from '../utils/Constants';

export const RTE_PREFIX = 'rte:';

const RICHTEXT_TOOLBAR_SETTINGS = {
    items: [
        'Undo', 'Redo',
        '|', 'Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontColor',
        'OrderedList', 'UnorderedList',
        '|', 'CreateTable',
        '|', 'CreateLink',
        '|', 'Image', 'Replace',
        '|', 'ClearFormat'
    ]
};
const RICHTEXT_TOOLBAR_SETTINGS_READONLY = {
    ...RICHTEXT_TOOLBAR_SETTINGS,
    enable: false
};
const MAX_IMAGE_FILE_SIZE_BYTES = MAX_IMAGE_FILE_SIZE_MB * BYTES_PER_MB;

export function RichTextArea(keyPrefix, name, value, error,
    onInputChanged,
    { label, required, readOnly, showLabel, unit },
    length,
    lineCnt,
    disabled) {

    const [editable, setEditable] = useState(!disabled && !readOnly);
    const refModal = useRef();
    const refRichText = useRef();

    useEffect(() => {

        setEditable(!disabled && !readOnly);
    }, [disabled, readOnly]);

    const fileToString = (file) => {
        const { size } = file;
        return `${file.name} [${size} bytes | ${(size / BYTES_PER_MB).toFixed(3)} MB]`;
    };

    const checkFileSize = (files) => {
        const dlg = refModal.current;

        let fileSizeString = [];
        let largeFiles = [];

        //Determine large files
        if (files instanceof FileList) {
            largeFiles = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                fileSizeString.push(fileToString(file));

                if (file.size > MAX_IMAGE_FILE_SIZE_BYTES) {
                    largeFiles.push(file);
                }
            }
        } else if (T.IsArrayNonEmpty(files)) {
            largeFiles = files.filter(x => x.size > MAX_IMAGE_FILE_SIZE_BYTES);
            fileSizeString = files.map(x => fileToString(x));
        }
        console.log(fileSizeString.join('\n'));

        //Notify user as needed
        if (T.IsArrayNonEmpty(largeFiles)) {
            const max = MAX_IMAGE_FILE_SIZE_MB.toFixed(2);

            dlg.Error(`Selected file size exceeds max allowable size [${max} MB]`,
                largeFiles.map(x => fileToString(x))
            );
            return false;
        }

        return true;
    }

    const actionBegin = (evt) => {
        const { requestType, originalEvent } = evt;
        console.log('actionBegin', requestType);

        switch (requestType) {
            case 'Images': {
                const { type, dataTransfer } = originalEvent;
                if (type === 'drop') {
                    const { files } = dataTransfer;
                    if (!checkFileSize(files)) {
                        evt.cancel = true;
                    }
                }
                break;
            }
            case 'Image': {
                const { itemCollection } = evt;
                if (itemCollection && itemCollection.url) {
                    const tokens = itemCollection.url.split('base64,');
                    if (tokens.length === 2) {
                        var sImage = tokens[1];
                        console.log(sImage.length, atob(sImage).length);
                    }
                }
                break;
            }
            case 'Paste': {
                const { clipboardData } = originalEvent;
                if (clipboardData) {
                    if (!checkFileSize(clipboardData.files)) {
                        evt.cancel = true;
                    }
                }
                break;
            }
            default:
                break;
        }
    }

    const getRichTextImageSettings = () => {
        return {
            insertImageSettings: {
                saveFormat: 'Base64'
            },
            imageUploadSuccess: (evt) => {
                console.log('imageUploadSuccess');
                const header = evt.Response.Headers;
                evt.File.Name = header.split('name: ')[1].split('\r')[0];
            },
            imageUploadFailed: (evt) => {
                console.log('imageUploadFailed');
                refModal.current.Error('Error uploading image');
            },
            //triggers when the image is selected or dragged into the insert image dialog
            imageSelected: (evt) => {
                // console.log('imageSelected', evt);
                const { filesData } = evt;
                if (!checkFileSize(filesData)) {
                    evt.cancel = true;
                }
            },
            actionBegin
        }
    }

    const isEmptyContent = (htmlText) => {
        const rte = refRichText.current;
        let isEmpty = rte.getText().trim().length === 0; //formatted string <p>, <a>, and <table>
        if (isEmpty) {
            isEmpty = !htmlText.includes('<img src=');  //<img>
        }
        return isEmpty;
    }

    const editorProps = getRichTextImageSettings();
    const editorServices = [
        Toolbar, HtmlEditor,
        Count,
        Link, QuickToolbar,
        Image,
        Table
    ];
    const toolbar = editable
        ? RICHTEXT_TOOLBAR_SETTINGS
        : RICHTEXT_TOOLBAR_SETTINGS_READONLY;

    return (
        <React.Fragment>
            {showLabel && buildInputLabel(label, required, unit, name)}
            <ComponentModal ref={refModal} />
            <RichTextEditorComponent toolbarSettings={toolbar}
                key={keyPrefix + name + '-rte'}
                value={value ? value.replace(RTE_PREFIX, '') : value} //Remove RTE-prefix
                required={required}
                enabled={!disabled}
                readonly={readOnly}
                maxLength={length}
                type='MultiRow'
                ref={refRichText}
                {...editorProps}
                //trigger 'change' event after a certain time period of idling 'saveInterval' has elpased
                //Default to 10000 ms, or 10 seconds
                //if there's changes since last 'change' is triggered
                autoSaveOnIdle={true}
                saveInterval={5000}
                showCharCount={true}
                //ONLY invoke when
                //(1) editor is blurred AND user done making changes
                //(2) editor has changes and is idle for 'saveInterval' time period
                change={(evt) => {
                    const { value: newVal } = evt;
                    const rteVal = newVal ? `${RTE_PREFIX}${newVal}` : newVal; //Add RTE-prefix
                    onInputChanged(evt, name, rteVal, isEmptyContent(newVal));
                }}
            >
                <Inject services={editorServices} />
            </RichTextEditorComponent>
            {error && buildFormFeedback(name, error)}
        </React.Fragment>
    );
}
