import React from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    CardFooter,
    Collapse
} from 'reactstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { GetBootTextColorClass } from '../utils/DataFormatUtil';
import * as T from '../utils/TypeUtil';

/**
 * @param ref [optional]
 * @param head [optional]
 * @param headColor [optional] One of the COLORs
 * @param footer [optional]
 * @param title [optional]
 * @param text [optional]
 * @param open [optional] Default is 'false'
 * @param toggle whether to allow user to show/hide panel children/body. Default is 'false'.
 * @param body [optional]
 * @param bodyStyles [optional]
 * @param bodyProps [optional]
 * @param bodyCname [optional]
 * @param height [optional]
 * @param cardCname [optional]
 * @param scroll [optional] Default is 'false'
 * @param cardProps [optional]
 * @param children [optional]
 * @param buttons [optional] additional buttons to add to the panel header section
 */
export default class BootPanel extends React.Component {
    constructor(props) {
        super(props);

        const { link, head, title, text, body, footer, height, scroll, open } = this.props;
        if (T.IsFunc(link)) { link(this); }

        this.state =
        {
            head: head || '',
            title: title || '',
            text: text || '',
            body: body || [],
            footer: footer || '',
            open: T.DefaultBool(open, false),
            height: height ? height : null,
            scroll: (T.DefaultBool(scroll, true)) ? 'scroll' : 'hidden'
        };
    }

    componentDidUpdate(prevProps) {
        const { head } = this.props;
        if (prevProps.head !== head && head !== this.state.head) {
            this.setState({ head: head });
        }
    }

    // Toggles the open state
    toggle() { this.setState({ open: !this.state.open }); }

    render() {

        let headSection = [];

        const { toggle, headColor, buttons, cardCname, bodyStyles, bodyProps, bodyCname }
            = this.props;
        const { head, title, text, body, footer, open, height }
            = this.state;
        const headClass = GetBootTextColorClass(headColor);

        if (T.IsDefined(head)) {
            headSection = (<CardHeader className={headClass}>{head}</CardHeader>);
        }

        let btnCnt = 0;
        if (toggle) {
            headSection = (
                <CardHeader
                    className={headClass}
                    style={{ cursor: 'pointer' }}
                    onClick={() => { this.toggle(); }}
                >
                    <Row className={'justify-content-between align-items-center'}>
                        <Col>{head}</Col>
                        {
                            T.IsArrayNonEmpty(buttons)
                            && buttons.map(btn => {
                                return (
                                    <Col xs="auto" key={`btn-${++btnCnt}`}>
                                        {btn}
                                    </Col>
                                );
                            })
                        }
                        <Col xs="auto">
                            <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight}
                                onClick={() => { this.toggle(); }}
                            />
                        </Col>
                    </Row>
                </CardHeader>
            );
        }

        let heightStyle = {
            maxHeight: this.state.height,
            overflow: this.state.scroll
        };

        let style = bodyStyles || {};
        if (height) {
            style = { ...style, ...heightStyle };
        }

        return (
            <Card {...this.props.cardProps} className={cardCname}>
                {headSection}
                <Collapse isOpen={open}>
                    <CardBody className={bodyCname}
                        style={style}
                        {...bodyProps}
                    >
                        {
                            T.IsDefined(title) &&
                            <CardTitle>{title}</CardTitle>
                        }
                        {
                            T.IsDefined(text) &&
                            <CardText>{text}</CardText>
                        }
                        {
                            Array.isArray(body)
                            && body
                        }
                        {
                            T.IsFunc(this.props.children)
                                ? this.props.children()
                                : this.props.children
                        }
                    </CardBody>
                </Collapse>
                {
                    T.IsDefined(footer) &&
                    <CardFooter>{footer}</CardFooter>
                }
            </Card>
        );
    }
}