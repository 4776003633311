import React, { useEffect, useRef, useState } from 'react';

import * as T from '../utils/TypeUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import ComponentModal from './ComponentModal';
import { copyTableColSortEntryList, ElementTableSortColumnList } from './ElementTableSortColumnList';

const TEXT_PRIMARY = `text-primary`;


export function ElementTableSortColumns({
    visible,
    defaultSorted,
    sortColumns,
    sortableColumns,
    onChange
}) {
    const [sorts, setSorts] = useState(copyTableColSortEntryList(sortColumns));
    const [show, setShow] = useState(visible);
    const refModal = useRef();

    useEffect(() => {
        const newSorts = copyTableColSortEntryList(sortColumns);
        setSorts(newSorts);
    }, [sortColumns]);

    const handleSortColumnsChanged = (list) => {
        const updated = T.IsArrayEmpty(list) ? copyTableColSortEntryList(defaultSorted) : list;
        setSorts(updated);
        if (T.IsFunc(onChange)) {
            onChange(updated);
        }
    };

    const handleEditButtonClicked = () => {

        const dlg = refModal.current;
        dlg.ComponentDialog('Table Sort Options',
            <ElementTableSortColumnList columns={sortableColumns} sortList={sorts}
                defaultSortList={defaultSorted}
                onChange={handleSortColumnsChanged}
            />,
            false,
            false,
            '',
            'Close',
            ''
        )
    };

    const handleAddColumnButtonClicked = () => {

    };

    const styles = {
        float: 'right',
        display: 'inline-flex',
        alignItems: 'center'
    };
    return (
        <div style={styles}>
            <ComponentModal link={(e) => refModal.current = e} />
            {
                show &&
                <React.Fragment>
                    <ElementTableSortColumnList columns={sortableColumns} sortList={sorts}
                        inline
                        styles={styles}
                        onChange={handleSortColumnsChanged}
                    />
                    <FontAwesomeIcon icon={faPenToSquare}
                        className={`me-1 ${TEXT_PRIMARY}`}
                        title='Edit Sort Options'
                        onClick={handleEditButtonClicked}
                    />
                </React.Fragment>
            }
            {/* <Button title='Sort' className='btn-primary ms-2'
                onClick={() => setShow(!show)}
            >
                <FontAwesomeIcon icon={show ? faChevronRight : faChevronLeft} />
            </Button> */}
        </div>
    );
}