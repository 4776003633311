import React, { useState } from 'react';
import * as T from '../utils/TypeUtil';
import { buildInputDateRaw, buildInputSsn, checkRequired, renderButton } from '../utils/EditorInputUtil';

import { PersonEditor, EPersonEditorMode } from './PersonEditor';
import { PersonWorkspace } from './PersonWorkspace';
import { PolicyEditor } from './PolicyEditor';
import { AddressEditor } from './AddressEditor';
import { EPersonTableMode, PersonTable } from './PersonTable';
import { AppUserFieldInfos, DenormalizeAndValidateSsn, EAppUserField, EElementField, EElementFieldInfos, EPersonFields, EPolicyFields, ETaskFields, NewPerson, NewPolicyMemberDto, PersonFieldInfos } from './EntityData';
import { TaskEditor } from './TaskEditor';
import ComponentModal from '../general/ComponentModal';
import FileUploadMultiple from '../general/FileUploadMultiple';
import { fetchWebApi } from '../utils/ClientUtil';
import { displayError } from '../utils/ExceptionUtil';
import { AppUserEditor } from './AppUserEditor';
import { EPolicyState, EYesNo } from '../AppEnums';
import { fetchRenewPolicyDate } from './EntityUtil';
import { FormatDate } from '../utils/DataFormatUtil';
import { ElementEditor } from './ElementEditor';
import { parseXML } from 'jquery';

export const POLICY_OWNER_PHONE_REQUIRED = false;

export function parsePhone(val) {
    let s = T.DefaultString(val, '');
    s = s.trim();
    s = s.replaceAll('+', '')
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '');
    const len = s.length;
    if (len === 10) {
        return s;
    }
    if (len === 1) {
        return s.substring(1);
    }
    return undefined;
}

export function formatPerson(e) {
    if (!T.IsDefined(e)) return undefined;

    return formatPersonName(e[EPersonFields.FirstName],
        e[EPersonFields.LastName],
        e[EPersonFields.MiddleName],
        e[EPersonFields.Suffix]);
}
export function formatTaskPerson(e) {
    if (!T.IsDefined(e)) return undefined;

    return formatPersonName(e[ETaskFields.TargetUserFirstName],
        e[ETaskFields.TargetUserLastName],
        e[ETaskFields.TargetUserMiddleName],
        e[ETaskFields.TargetUserSuffix]);
}

export function formatPersonName(first, last, middle, suffix) {
    var s = `${last}, ${first}`;
    if (T.IsDefined(middle)) {
        s = `${s} ${middle}`;
    }
    if (T.IsDefined(suffix)) {
        s = `${s} ${suffix}`;
    }
    return s;
}

export const validatePersonData = (e, refErrors, isOwner = true, fieldName = undefined) => {

    const fields = T.IsDefined(fieldName) && T.IsDefined(EPersonFields[fieldName])
        ? [fieldName]
        : Object.keys(EPersonFields);
    for (const field of fields) {

        delete refErrors[field];

        const info = PersonFieldInfos[field];
        if (!T.IsDefined(info)) continue;

        if (!checkRequired(refErrors, e, field)) {
            //field is empty
            if (!info.required) {
                //optional field, remove the error
                delete refErrors[field];
            }
            continue;
        }
        //field has data

        switch (field) {
            case EPersonFields.PhoneNumber:
                {
                    const val = e[field];
                    e[field] = parsePhone(val);
                    if (!e[field]) {
                        refErrors[field] = 'Invalid phone number';
                    }
                    break;
                }
            case EPersonFields.SsnHash:
                {
                    const err = DenormalizeAndValidateSsn(e); // SSN: Validate Person
                    if (err) {
                        refErrors[field] = err;
                    }
                    break;
                }
            case EPersonFields.Ssn4:
            default:
                break;
        }
    };
}


export const validateAppUserData = (e, refErrors, fieldName = undefined) => {

    const fields = T.IsDefined(fieldName) && T.IsDefined(EAppUserField[fieldName])
        ? [fieldName]
        : Object.keys(EAppUserField);
    for (const field of fields) {

        delete refErrors[field];

        const info = AppUserFieldInfos[field];
        if (!T.IsDefined(info)) continue;

        let fieldValid = !info.required
            || checkRequired(refErrors, e, field);
        if (!fieldValid) {
            continue; //check the next field
        }

        switch (field) {
            case EAppUserField.PhoneNumber:
                {
                    const val = e[field];
                    if (!val) continue;
                    e[field] = parsePhone(val);
                    if (!e[field]) {
                        refErrors[field] = 'Field is invalid';
                    }
                    break;
                }
            case EAppUserField.UserName:
                {
                    const val = e[field];
                    if (val.trim().length < 4) {
                        refErrors[field] = 'Field length must be equal or greater than 4';
                    }
                    break;
                }
            case EAppUserField.EmailConfirmed:
                //convert to bool
                if (!T.IsBool(e[field])) {
                    e[field] = e[field] === EYesNo.YES;
                }
                break;
            default:
                break;
        }
    };
}

export const validateElementData = (e, refErrors, fieldName = undefined) => {

    const fields = T.IsDefined(fieldName) && T.IsDefined(EElementField[fieldName])
        ? [fieldName]
        : Object.keys(EElementField);
    for (const field of fields) {

        delete refErrors[field];

        const info = EElementFieldInfos[field];
        if (!T.IsDefined(info)) continue;

        let fieldValid = !info.required
            || checkRequired(refErrors, e, field);
        if (!fieldValid) {
            continue; //check the next field
        }

        switch (field) {
            case EElementField.Id:
            case EElementField.Name:
                {
                    const val = e[field];
                    if (val.trim().length >= 200) {
                        refErrors[field] = 'Field length must be equal or greater than 200';
                    }
                    break;
                }
            default:
                break;
        }
    };
}

export const createSelectRowOptions = (multiSelect, selectedIds, onSelect, excludeIds) => {
    const options =
    {
        mode: multiSelect ? 'checkbox' : 'radio',
        clickToSelect: true,
        hideSelectAll: true,
        selected: selectedIds,
        nonSelectable: excludeIds,
        bgColor: '#00BFFF',
        onSelect: onSelect
    };
    return options;
}

export function renderOptionsCell(parentName, options) {

    const keyPrefix = `${parentName || 'table'}-options-`;
    return (
        <div className='btn-group'>
            {
                options.map((x) => {
                    if (x.hidden) return <React.Fragment />;
                    const { formatter } = x;
                    if (T.IsDefined(formatter)) {
                        return formatter;
                    }
                    const { name, onClick, disabled, className, faIcon } = x;

                    return renderButton(`${keyPrefix}${name}`, name, onClick,
                        disabled, className, faIcon);
                })
            }
        </div>)
}

export const displayFileUploadDialog = (title, linkModal, postUrl, callback, accept = undefined,
    resultFormatter = undefined,
    multiple = false) => {

    let fileList;
    let editorModal;
    linkModal.ComponentDialog(title,
        <React.Fragment>
            <ComponentModal link={(e) => editorModal = e} />
            <FileUploadMultiple multiple={multiple} accept={accept}
                onChange={(files) => { fileList = files }} />
        </React.Fragment>,
        () => { //ok
            if (!T.IsArrayNonEmpty(fileList)) {
                editorModal.Error('Please select one or more files');
                return;
            }
            editorModal.Loading('Uploading...');

            const body = new FormData();
            fileList.forEach((item, i) => {
                body.append(`${i}`, item.name);
                body.append(`${i}`, item.file, item.file.name);
            });

            fetchWebApi(postUrl,
                {
                    method: 'POST',
                    body: body
                })
                .then((result) => {
                    const s = fileList.length === 1 ? fileList[0].file.name : 'files';
                    const sNote = T.IsFunc(resultFormatter) ? resultFormatter(result) : undefined;
                    linkModal.Success(`Successfully uploaded ${s}`, sNote,
                        () => {
                            linkModal.close();
                            if (T.IsFunc(callback)) callback();
                        });
                })
                .catch((error) => {
                    displayError(editorModal, 'Error uploading file(s)', error);
                });
        },
        () => { //cancel
            linkModal.close();
        });
}

export const displayPersonWorkspaceDialog = (linkModal, id, callback) => {

    let editor = undefined;

    linkModal.ComponentDialog('User',
        <PersonWorkspace id={id}
            link={(e) => { editor = e; }} />,
        () => { //ok
            editor.onSave(callback);
        },
        () => { //cancel
            linkModal.close();
        },
        'Close',
        ''
    );
}

/**
 * 
 * @param {ComponentModal} linkModal 
 * @param {Guid} id [optional] ID of the person to be viewed/edited,
 * applicable when view/edit existing
 * @param {function} callback
 */
export function displayAppUserEditorDialog(linkModal, id, callback) {

    let editor = undefined;
    let changed = false;

    linkModal.ComponentDialog(T.IsDefined(id) ? 'Edit User' : 'Add New User',
        <AppUserEditor id={id} key={`displayAppUser-${id}`}
            link={(e) => { editor = e; }}
        />,
        (applyBtn) => { //ok
            editor.onSave((updated) => { //user
                if (updated) {
                    changed = true;
                }
                if (!applyBtn) linkModal.close();
                if (T.IsFunc(callback)) callback(changed);
            });
        },
        () => { //cancel
            linkModal.close();
            if (T.IsFunc(callback)) callback(changed);
        },
        'Apply'
    );
}

/**
 * 
 * @param {ComponentModal} linkModal 
 * @param {string} id [optional] ID of the element to be viewed/edited,
 * applicable when view/edit existing
 * @param {function} callback
 */
export function displayElementEditor(linkModal, elementType, url, id, callback) {

    let editor = undefined;
    let changed = false;

    linkModal.ComponentDialog(T.IsDefined(id) ? `Edit ${elementType}` : `Add New ${elementType}`,
        <ElementEditor id={id} key={`display${elementType}-${id}`}
            url={url}
            link={(e) => { editor = e; }}
        />,
        (applyBtn) => { //ok
            editor.onSave((updated) => { //element
                if (updated) {
                    changed = true;
                }
                if (!applyBtn) linkModal.close();
                if (T.IsFunc(callback)) callback(changed);
            });
        },
        () => { //cancel
            linkModal.close();
            if (T.IsFunc(callback)) callback(changed);
        },
        'Apply'
    );
}

/**
 * 
 * @param {ComponentModal} linkModal 
 * @param {Guid} id [optional] ID of the person to be viewed/edited,
 * applicable when view/edit existing
 * @param {import('../AppConstants').IAppElementHandler} callback invoked when dialog is closed
 */
export function displayPersonEditorDialog(linkModal, name, id, callback, readOnly, editing,
    hideAttachments, hideNotes, hideLogs) {

    let editor = undefined;

    linkModal.ComponentDialog(T.IsDefined(id) ? 'Edit Individual' : 'Add New Individual',
        <PersonEditor id={id} key={`displayPerson-${id}`}
            name={name}
            displayMode={EPersonEditorMode.DEFAULT}
            editing={editing}
            disabled={readOnly}
            hideAttachments={hideAttachments}
            hideNotes={hideNotes}
            hideLogs={hideLogs}
            link={(e) => { editor = e; }} />,
        (applyBtn) => { //ok
            editor.onSave((updatedElement) => { //person
                if (!applyBtn) linkModal.close();
                if (T.IsFunc(callback)) callback(updatedElement);
            });
        },
        () => { //cancel
            linkModal.close();
            if (T.IsFunc(callback)) callback();
        },
        'Apply'
    );
}

/**
 * @param {ComponentModal} linkModal modal for create/view/edit a policy
 * @param {string} name name (not title) of the editor
 * @param {*} id [optional] ID of the policy, applicable when view/edit existing policy
 * @param {*} ownerId [optional] ID of the policy owner, applicable when creating new policy
 * @param {*} copyId [optional] ID of the policy to create new, applicable when creating new policy
 * @param {*} canChangePolicyOwner [optional] Whether user can modify policy owner. Default to 'false'
 * @param {*} callback [optional] invoked when policy dialog/model is closed. Params=[changed: boolean]
 * @param {*} onPersonChanged [optional] invoked when a person is changed
 */
export function displayPolicyEditorDialog(linkModal, name, id, ownerId, copyId,
    canChangePolicyOwner = false,
    callback,
    onPersonChanged = undefined,
    newProps = undefined,
    editing = false) {

    let editor = undefined;
    let changed = false;

    const pState = (newProps || {})[EPolicyFields.State];
    const title = copyId
        ? (pState === EPolicyState.DoNotRenew ? 'Not Renew Policy' : 'Copy Policy')
        : (id ? 'Policy' : 'New Policy');

    linkModal.ComponentDialog(title,
        <PolicyEditor id={id} name={name}
            ownerId={ownerId}
            copyId={copyId}
            newProps={newProps}
            onPersonChanged={onPersonChanged}
            canChangePolicyOwner={canChangePolicyOwner}
            editing={editing}
            onRenewPolicyClick={(policyId, isDNR) => handleRenewPolicyClicked(linkModal, name, policyId, isDNR, callback, onPersonChanged)}
            link={(e) => { editor = e; }} />,
        (applyBtn) => { //ok
            editor.onSave((updated) => { //policy
                if (updated) {
                    changed = true;
                }
                if (!applyBtn) linkModal.close();
                if (T.IsFunc(callback)) callback(changed);
            });
        },
        () => { //cancel
            linkModal.close();
            if (T.IsFunc(callback)) callback(changed);
        },
        'Apply'
    );
}

const handleRenewPolicyClicked = (linkModal, name, policyId, isDNR,
    callback, onPersonChanged) => {

    const sAction = isDNR ? 'NOT renew the policy' : 'renew the policy';
    promptRenewalPolicyDate(linkModal, policyId, sAction, (succeed) => {
        if (!succeed) return;

        const copyProps = {};
        if (isDNR) {
            copyProps[EPolicyFields.State] = EPolicyState.DoNotRenew;
        }
        linkModal.close();
        displayPolicyEditorDialog(linkModal, `${name}_renew`,
            undefined, //id
            undefined, //ownerId
            policyId, //copyId
            true, //canChangePolicyOwner
            callback,
            onPersonChanged,
            copyProps,
            true,
        );
    });
};

/**
 * @param {ComponentModal} linkModal 
 * @param {string} name 
 * @param {string} id 
 * @param {*} newTask 
 * @param {function} callback 
 */
export function displayTaskEditorDialog(linkModal, name, id, newTask, canGoToPolicy, canGoToContact,
    callback, onPersonChanged = undefined) {

    const showPolicyEditor = (policyId) => {
        displayPolicyEditorDialog(linkModal, `${name}_addEditPolicy`, policyId,
            undefined, // ownerId
            undefined, // copyId
            false, //canChangePolicyOwner
            (updated) => { // callback
                linkModal.close();
            },
            onPersonChanged);
    };
    const checkUnsavedTaskBeforeGoToPolicy = (policyId, taskReadonly) => {
        if (taskReadonly) {
            showPolicyEditor(policyId);
            return;
        }
        promptToDiscardUnsavedChanges(editorModal, () => { showPolicyEditor(policyId); });
    };

    const showContactEditor = (contactId) => {
        displayPersonEditorDialog(linkModal, `${name}_addEditContact`, contactId,
            (updated) => { // callback
                linkModal.close();
            },
            false, //readOnly
            false, //editing
            false, //hideAttachments
            false, //hideNodes
            false); //hideLogs
    };
    const checkUnsavedTaskBeforeGoToContact = (contactId, taskReadonly) => {
        if (taskReadonly) {
            showContactEditor(contactId);
            return;
        }
        promptToDiscardUnsavedChanges(editorModal, () => { showContactEditor(contactId); });
    };

    const enableGoToPolicy = T.DefaultBool(canGoToPolicy, true);
    const enableGoToContact = T.DefaultBool(canGoToContact, true);
    let editor = undefined;
    let editorModal = undefined;
    let changed = false;

    linkModal.ComponentDialog('Task',
        <React.Fragment>
            <ComponentModal link={(e) => editorModal = e} />
            <TaskEditor id={id} name={name}
                data={newTask}
                link={(e) => { editor = e; }}
                onGoToPolicyClick={enableGoToPolicy ? checkUnsavedTaskBeforeGoToPolicy : undefined}
                onGoToContactClick={enableGoToContact ? checkUnsavedTaskBeforeGoToContact : undefined}
            />
        </React.Fragment>,
        (applyBtn) => { //ok
            editor.onSave((updated) => { //task
                if (updated) {
                    changed = true;
                }
                if (!applyBtn) linkModal.close();
                if (T.IsFunc(callback)) callback(changed);
            });
        },
        () => { //cancel
            linkModal.close();
            if (T.IsFunc(callback)) callback(changed);
        },
        'Apply'
    );
}

export function displayAddressEditorDialog(linkModal, id, callback) {

    let editor = undefined;
    let changed = false;

    linkModal.ComponentDialog('Address',
        <AddressEditor id={id}
            link={(e) => { editor = e; }} />,
        (applyBtn) => { //ok
            editor.onSave((updated) => { //address
                if (updated) {
                    changed = true;
                }
                if (!applyBtn) linkModal.close();
                if (T.IsFunc(callback)) callback(changed);
            });
        },
        () => { //cancel
            linkModal.close();
            if (T.IsFunc(callback)) callback(changed);
        },
        'Apply'
    );
}

export function displayPersonSelectionDialog(linkModal, key, title, userId, disabled,
    selectRowOptions, onOkClick, onPersonChanged = undefined) {

    const mode = T.IsDefined(userId) ? EPersonTableMode.REL : EPersonEditorMode.DEFAULT;

    linkModal.ComponentDialog(title,
        <PersonTable selectRowOptions={selectRowOptions}
            hideAttachments={true}
            name={key}
            disabled={disabled}
            userId={userId} displayMode={mode}
            onElementChanged={onPersonChanged}
        />,
        () => { //ok
            if (T.IsDefined(onOkClick)) {
                onOkClick((success) => {
                    if (success) {
                        linkModal.close();
                    }
                });
            }
            else {
                linkModal.close();
            }
        },
        false //Cancel
    );
}

export function promptToDiscardUnsavedChanges(linkModal, done) {

    linkModal.Confirm('Any unsaved changes will be discarded',
        'Are you sure you want to continue?',
        () => { // OK
            linkModal.close(); //close the confirm dialog
            done();
        },
        () => { // Cancel
            linkModal.close();
        },
        'Yes', 'No');
}

export function promptRenewalPolicyDate(linkModal, policyId, sAction, done) {

    fetchRenewPolicyDate(linkModal, policyId, (succeed, date) => {
        if (!succeed) {
            done();
            return;
        }
        if (!date) {
            done(true);
            return;
        }

        linkModal.Confirm(`User already has a policy at ${FormatDate(date)}`,
            `Are you sure you want to continue to ${sAction}?`,
            () => { // OK
                linkModal.close(); //close the confirm dialog
                done(true);
            },
            () => { // Cancel
                linkModal.close();
            },
            'Yes', 'No');
    });
}

export function parseMembersFromTable(policyId, htmlString) {
    const result = [];
    const tdata = parseFromXmlTable(htmlString);
    if (T.IsArrayNonEmpty(tdata)) {

        tdata.forEach(row => {
            if (row.length > 5) {
                let i = 0;

                const fullname = row[i++];
                const nameTokens = fullname.split(' ');
                const nameTokenCnt = nameTokens.length;
                const sLast = nameTokens[nameTokenCnt - 1];
                let sMiddle = undefined;
                if (nameTokenCnt > 2) {
                    sMiddle = nameTokens[nameTokenCnt - 2];
                    nameTokens.splice(nameTokenCnt - 2, 2);
                } else {
                    nameTokens.splice(nameTokenCnt - 1, 1);
                }
                const sFirst = nameTokens.join(' ');

                const sGender = row[i++];
                const sApply = row[i++];
                const sDob = row[i++];
                const sSsn = row[i++];
                const sSsn4 = sSsn.length >= 4 ? sSsn.substring(sSsn.length - 4, sSsn.length) : undefined;
                const bApply = sApply === 'Yes';

                const p = NewPerson();
                p[EPersonFields.FirstName] = sFirst;
                p[EPersonFields.LastName] = sLast;
                p[EPersonFields.MiddleName] = sMiddle;
                p[EPersonFields.Gender] = sGender.substring(0, 1);
                p[EPersonFields.DOB] = sDob;
                p[EPersonFields.SsnHash] = sSsn;
                p[EPersonFields.Ssn4] = sSsn4;

                const member = NewPolicyMemberDto(undefined, policyId, p, undefined, bApply);
                result.push(member);
            }
        });
    }

    return result;
}

export function parseFromXmlTable(htmlString) {
    const result = [];

    try {
        const doc = parseXML(htmlString);
        if (!doc) return result;


        let tbody = doc;
        const table = doc.querySelector('table');
        if (table) {
            tbody = table.querySelector('tbody');
        }
        if (!tbody) return result;

        if (tbody) {

            const tRows = tbody.querySelectorAll('tr');
            tRows.forEach((tRow) => {
                const tCols = tRow.querySelectorAll('td');

                const rowData = [];
                tCols.forEach((tCol) => {
                    const { textContent } = tCol;
                    rowData.push(textContent);
                });

                result.push(rowData);
            });
        }
    } catch (ex) { }
    return result;
}

export class SsnEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }
    getValue() {
        return this.state.value;
    }
    render() {
        const { name, label, showLabel, onUpdate } = this.props;
        const body = buildInputSsn(name + 'val', this.state, {}, //errors
            {
                name: "value",
                label,
                showLabel,
                onBlur: () => {
                    onUpdate(this.getValue());
                }
            },
            (evt, name, newVal) => {
                this.setState({ value: newVal });
            });
        return [
            body
        ];
    }
}


export function DateEditor({ value: inValue, name, label, showLabel, onUpdate }) {

    const [value, setValue] = useState(inValue);

    return buildInputDateRaw(name + 'val', value, undefined, //errors
        {
            name,
            label,
            showLabel,
            onBlur: () => {
                onUpdate(value);
            }
        },
        (evt, name, newVal) => {
            setValue(newVal);
        });
}


