import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    EElementTableColFilterType,
    ElementTableCellSelectEditor,
    ElementTablePageOptions,
    FormatElementTableCellBoolToYesNo,
    FormatElementTableCellDateDob,
    FormatElementTableCellSsn,
    TableColSortEntry,
    ToElementTableSelectOptions
} from '../general/ElementTableUtil';
import ComponentModal from '../general/ComponentModal';
import { FormatPhoneMasked, GetAge } from '../utils/DataFormatUtil';
import * as T from '../utils/TypeUtil';
import {
    EGenderOptions,
    EImmigrationStatusOptions,
    EMartialStatusOptions,
    ERelationshipType,
    ERelationshipTypeOptions,
    EYesNoOptions,
} from '../AppEnums';

import {
    EPersonFields as EFields,
    EPersonFieldLabels as EFieldLabels,
    EPersonRelFields as ERelFields,
    EPersonRelFieldLabels as ERelFieldLabels,
    NewUserRel,
    EPolicyMemberFields,
    EPersonRelFields,
    NormalizeSsn,
} from './EntityData';

import {
    renderOptionsCell,
    displayPersonEditorDialog,
    displayPersonSelectionDialog,
    createSelectRowOptions,
    formatPerson,
    SsnEditor,
    DateEditor
} from './EntityEditorUtil';

import '../general/css/general.css';

import { faEdit, faTrash, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { URL_PERSON } from '../AppConstants';
import { deleteUserRel, saveUserRels, updateUserRel } from './EntityUtil';
import BootAlert from '../general/BootAlert';

export const EPersonTableMode = {
    DEFAULT: 'DEFAULT',
    MEMBER: 'MEMBER',
    REL: 'REL',
}

const COL_AGE = "Age";

/**
 * @param loginUserId ID of the login user
 * @param userID [optional] ID of the person to display related users for,
 * applicable when displaying relationship between individuals
 * @param name [optional] component name
 * @param elementName [optional]
 * @param disableLoadOnInit [optional]
 * @param data [optional] list of elements to populate the table with,
 * applicable when display mode is MEMBER
 * @param sizePerPage [optional] number of rows per page
 * @param {EPersonEditorMode} displayMode [optional] display mode
 * @param canDelete [optional] whether user can delete a row in the 
 * @param buttons [optional] additional table buttons
 * @param onElementChanged [optional] Func(in element) to handle row data has changed event.
 * @param onOpen [optional] Func(in element) to handle tthe user clicking on button
 * to open the selected row
 * @param onNew [optional] Func() to handle '+' button click event
 * @param onNewIcon [optional] font awesome icon to overwrite existing one
 * @param onDelete [optional] Func(in element) to overwrite default delete function.
 * Return a flag to indicate whether operation is success
 * @param onAddNew [optional] Func<in element, in callback(in success)> to overwrite
 * default handler for handling new row added event 
 * Is required when in MEMBER mode
 * @param cellEditOptions [optional]
 * @param onBeforeSaveTableCell [optional] Func<in oldVal, in newVal, in row, in col, in done)
 * to overwrite default handler for processing event that occurred before table saves changes 
 * user made to an table cell.
 * Default is used to post UserUserRel.RelType changes to server 
 * @param selectRowOptions [optional]
 */
export class PersonTable extends Component {

    constructor(props) {
        super(props);
        this.mount = false;
        this.refModal = undefined;
        this.refModalXL = undefined;
        this.refAlert = undefined;
        this.refTable = undefined;

        this.renderRowOptions = this.renderRowOptions.bind(this);
        this.handleFormatUrl = this.handleFormatUrl.bind(this);
        this.handleNormalizeFetchData = this.handleNormalizeFetchData.bind(this);
        this.handleNewEditButtonClick = this.handleNewEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleBeforeSaveTableCell = this.handleBeforeSaveTableCell.bind(this);

        const {
            link, name, sizePerPage, cellEditOptions, displayMode,
        } = this.props;
        this.name = name || 'tablePerson';
        this.tableMode = displayMode || EPersonTableMode.DEFAULT;


        const defaultCellEditOptions = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: this.handleBeforeSaveTableCell
        };
        this.columns = this.getColumns();

        this.state = {
            data: [],
            errors: {},
            pageOptions: new ElementTablePageOptions(false, sizePerPage),
            cellEditOptions: T.IsDefined(cellEditOptions)
                ? { ...defaultCellEditOptions, ...cellEditOptions }
                : defaultCellEditOptions,
            defaultSorted: this.tableMode === EPersonTableMode.MEMBER
                ? [
                    new TableColSortEntry(EFields.DOB, false),
                    new TableColSortEntry(EFields.FirstName),
                    new TableColSortEntry(EFields.LastName),
                ]
                : [
                    new TableColSortEntry(EFields.FirstName),
                    new TableColSortEntry(EFields.LastName),
                ],
        };

        if (T.IsFunc(link)) {
            link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    componentWillUnmount() {
        this.mount = false;
    }

    componentDidMount() {
        this.mount = true;
    }

    componentDidUpdate(prevProps, prevState) {
        const { userId, sizePerPage } = this.props;

        if (userId !== prevProps.userId) {
            this.refTable.reloadTable();
        }

        let updated = {};
        if (sizePerPage !== prevProps.sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            updated = {
                ...updated,
                pageOptions: updatedPageOptions
            };
        }

        if (Object.keys(updated).length > 0) {
            this.setState(updated);
        }
    }

    getColumns() {
        const viewMember = this.tableMode === EPersonTableMode.MEMBER;
        const viewRel = this.tableMode === EPersonTableMode.REL;
        const viewDefault = !(viewMember || viewRel);

        const formatPolicyOwnerCol = viewMember
            ? (cellVal, row) => {
                if (row[EFields.IsPolicyOwner]) {
                    return <span className='text-success'>{cellVal}</span>;
                }
                return cellVal;
            }
            : undefined;

        const cols = [
            {
                text: EFieldLabels.Id,
                dataField: EFields.Id,
                hidden: true,
            },
            {
                text: EFieldLabels.FirstName,
                dataField: EFields.FirstName,
                filter: EElementTableColFilterType.TEXT,
                formatter: formatPolicyOwnerCol,
                editable: viewMember,
                headerStyle: { width: '15%' },
            },
            {
                text: EFieldLabels.MiddleName,
                dataField: EFields.MiddleName,
                filter: EElementTableColFilterType.TEXT,
                formatter: formatPolicyOwnerCol,
                editable: viewMember,
                headerStyle: { width: '5%' },
            },
            {
                text: EFieldLabels.LastName,
                dataField: EFields.LastName,
                filter: EElementTableColFilterType.TEXT,
                formatter: formatPolicyOwnerCol,
                editable: viewMember,
                headerStyle: { width: '15%' },
            },
            {
                text: EFieldLabels.Suffix,
                dataField: EFields.Suffix,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
                formatter: formatPolicyOwnerCol,
                editable: viewMember,
                headerStyle: { width: '5%' },
            },
            {
                text: EFieldLabels.NickName,
                dataField: EFields.NickName,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
                formatter: formatPolicyOwnerCol,
                editable: viewMember,
            },
            {
                text: EFieldLabels.DOB,
                dataField: EFields.DOB,
                formatter: FormatElementTableCellDateDob,
                // editor: ElementTableCellDateEditor,
                filter: EElementTableColFilterType.TEXT,
                editable: viewMember,
                headerStyle: { width: '8%' },
                editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    const sKey = `${this.name}-${row.Id}-ssn`;
                    return (
                        <DateEditor key={sKey} name={sKey}
                            {...editorProps}
                            value={row[EFields.DOB]} /> // DOB: PersonTable cell editor
                    );
                }
            },
            {
                text: COL_AGE,
                dataField: COL_AGE,
                isDummyField: true,
                sort: false,
                formatter: (cellVal, row) => {
                    const age = GetAge(row[EFields.DOB]);
                    if (T.IsNum(age) && age < 19) {
                        return <span className='text-info'>{age}</span>;
                    }
                    return age;
                },
            },
            {
                text: EFieldLabels.Ssn4,
                dataField: EFields.Ssn4,
                hidden: viewRel,
                filter: EElementTableColFilterType.TEXT,
                editable: viewMember,
                formatter: (cellVal, row) => {
                    return FormatElementTableCellSsn(
                        row.Id,
                        cellVal,
                        row[EFields.SsnHash]) // SSN Format: PersonTable
                },
                editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    const sKey = `${this.name}-${row.Id}-ssn`;
                    return (
                        <SsnEditor key={sKey} name={sKey} id={sKey}
                            {...editorProps}
                            value={row[EFields.SsnHash]} /> // SSN: PersonTable cell editor
                    );
                }
            },
            {
                text: EFieldLabels.IsPolicyOwner,
                dataField: EFields.IsPolicyOwner,
                hidden: !viewDefault,
                alwaysHidden: !viewDefault,
                sort: false,
                headerStyle: { width: '5%' },
                formatter: FormatElementTableCellBoolToYesNo,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
            },
            {
                text: EFieldLabels.PhoneNumber,
                dataField: EFields.PhoneNumber,
                formatter: (cellVal, row) => FormatPhoneMasked(row[EFields.Id], cellVal),
                filter: EElementTableColFilterType.TEXT,
                editable: viewMember,
                headerStyle: { width: '8%' },
            },
            {
                text: EFieldLabels.Email,
                dataField: EFields.Email,
                hidden: viewMember,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFieldLabels.Gender,
                dataField: EFields.Gender,
                hidden: viewDefault,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EGenderOptions),
                editable: viewMember,
                editor: ElementTableCellSelectEditor(EGenderOptions),
            },
            {
                text: EFieldLabels.MaritalStatus,
                dataField: EFields.MaritalStatus,
                hidden: !viewMember,
                alwaysHidden: !viewMember,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EMartialStatusOptions),
                editable: viewMember,
                editor: ElementTableCellSelectEditor(EMartialStatusOptions),
            },
            {
                text: EFieldLabels.ImmigrationStatus,
                dataField: EFields.ImmigrationStatus,
                hidden: viewDefault,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EImmigrationStatusOptions),
                editable: viewMember,
                editor: ElementTableCellSelectEditor(EImmigrationStatusOptions),
            },
            {
                text: EFieldLabels.DriverLicense,
                dataField: EFields.DriverLicense,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFieldLabels.HomeAddress,
                dataField: EFields.HomeAddress,
                hidden: true,
                filter: false,
                sort: false
            },
            {
                text: EFieldLabels.HomeState,
                dataField: EFields.HomeState,
                hidden: true,
                filter: false,
                sort: false
            },
            {
                text: EFieldLabels.DNC,
                dataField: EFields.DNC,
                hidden: true,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
                formatter: FormatElementTableCellBoolToYesNo,
            },
            {
                text: EFieldLabels.Company,
                dataField: EFields.Company,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
        ];

        if (viewMember) {
            cols.splice(0, 0, {
                text: EPolicyMemberFields.Apply,
                dataField: EPolicyMemberFields.Apply,
                formatter: FormatElementTableCellBoolToYesNo,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
                editable: true,
                editor: ElementTableCellSelectEditor(EYesNoOptions)
            });
        }
        if (!viewDefault) {

            const isColEditable = (val, row, rowIndex, columnIndex) => {
                return (viewRel || viewMember) && val !== ERelationshipType.Self;
            };
            cols.push(
                {
                    text: ERelFieldLabels.RelType,
                    dataField: ERelFields.RelType,
                    hidden: viewDefault,
                    alwaysHidden: viewDefault,
                    editable: isColEditable,
                    filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(ERelationshipTypeOptions),
                    editor: ElementTableCellSelectEditor(ERelationshipTypeOptions)
                });
        }

        cols.push(
            {
                text: '',
                dataField: 'options',
                isDummyField: true,
                sort: false,
                allowFilter: false,
                formatter: (cellVal, row) => {
                    return this.renderRowOptions(row);
                },
            });
        return cols;
    }

    handleNewEditButtonClick(e) {

        const { userId, onNew } = this.props;
        if (T.IsDefined(e)) {
            this.addUpdatePerson(e.Id); //update existing
            return;
        }
        if (!T.IsDefined(userId)) {
            this.addUpdatePerson(); //add new
            return;
        }

        if (this.tableMode === EPersonTableMode.MEMBER) {

            if (T.IsFunc(onNew)) {
                onNew();
                return;
            }
            this.addNewMember(userId); //add member
            return;
        }

        this.addNewRel(userId); //add new rel
    }

    addUpdatePerson(personId = undefined) {

        //Add new person
        displayPersonEditorDialog(this.refModalXL, `${this.name}_addEditContact`, personId,
            (updated) => {
                if (!updated || !this.mount) return;

                const { onElementChanged } = this.props;

                //reload the table in case changes affect sorting/filter
                this.refTable.reloadTable();

                if (T.IsDefined(onElementChanged)) {
                    onElementChanged(updated);
                }
            },
            this.props.disabled,
            false, //editing
            this.props.hideAttachments,
            this.props.hideNotes,
            this.props.hideLogs
        );
    }

    /**
     * @param {Guid} userId ID of the person to create the new relationship for
     */
    addNewRel(userId, isMember = false) {
        const { onElementChanged, elementName } = this.props;
        const { data } = this.state;
        const key = `${this.name}_addRel`;

        const excludeIds = data.map(x => x.Id);
        excludeIds.push(userId);

        var selected = [];
        const sName = T.stringIsNullOrEmpty(elementName) ? '' : ` - ${elementName}`;
        displayPersonSelectionDialog(this.refModalXL, key, `Add related individual${sName}`,
            undefined, //Add anyone
            true, //no update existing
            createSelectRowOptions(true, //multiselect
                undefined, //no pre-select
                (row, isSelect) => {
                    if (isSelect) {
                        selected.push(row);
                    } else {
                        selected = selected.filter(x => x.Id !== row.Id);
                    }
                },
                excludeIds), //excluded those already associated
            (callback) => { // OK
                const { data } = this.state;

                //De-normalize table entry to UserUserRel
                const updatedRels = data.map(x =>
                    NewUserRel(x.RelId, userId, x.Id, x[ERelFields.RelType])
                );

                //Add newly selected ones
                selected.forEach(x => {
                    updatedRels.push(NewUserRel(undefined, userId, x.Id));
                });

                //Send to server
                saveUserRels(this.refModal, userId, updatedRels, (success) => {
                    callback(success);
                    this.reloadTable();
                });
            },
            (updated) => { // PersonChanged

                //reload the table in case changes affect sorting/filter
                this.refTable.reloadTable();

                if (T.IsDefined(onElementChanged)) {
                    onElementChanged(updated);
                }
            }
        )
    }

    addNewMember(policyOwnerId) {

        const { onElementChanged, elementName } = this.props;
        const { data } = this.state;
        const key = `${this.name}_addMember`;

        const excludeIds = data.map(x => x.Id);
        excludeIds.push(policyOwnerId);

        var selected = [];
        const sName = T.stringIsNullOrEmpty(elementName) ? '' : ` - ${elementName}`;
        displayPersonSelectionDialog(this.refModalXL, key, `Add related individual as Policy Member${sName}`,
            policyOwnerId, //Only add people related to the policy owner
            false,
            createSelectRowOptions(true, //multiselect
                undefined, //no pre-select
                (row, isSelect) => {
                    if (isSelect) {
                        selected.push(row);
                    } else {
                        selected = selected.filter(x => x.Id !== row.Id);
                    }
                },
                excludeIds),
            (callback) => { // OK

                //validate selection
                if (selected.length === 0) {
                    this.refModal.Warning('Please specify one or more individual', '', () => {
                        callback(false);
                    });
                    return;
                }
                const { onAddNew } = this.props;

                if (T.IsFunc(onAddNew)) {
                    onAddNew(selected, (success) => {
                        callback(success);
                    });
                    return;
                }

                this.refModal.Alert(`Operation is not yet supported current display mode [${this.tableMode}]`,
                    callback(false)
                );
            },
            (updated) => { // PersonChanged

                //reload the table in case changes affect sorting/filter
                this.refTable.reloadTable();

                if (T.IsDefined(onElementChanged)) {
                    onElementChanged(updated);
                }
            }
        )
    }

    handleDeleteButtonClick(selected) {
        const { userId, onDelete } = this.props;

        if (T.IsFunc(onDelete)) {
            return onDelete(selected);
        }

        if (this.tableMode === EPersonTableMode.REL) {

            const sSelected = formatPerson(selected);
            this.refModal.Confirm(`Are you sure you want to remove the association with ${sSelected}`,
                'Action cannot be undone',
                () => {
                    deleteUserRel(this.refModal, userId, selected.Id, (success) => {
                        if (success) {
                            this.refTable.reloadTable();
                        }
                    })
                },
                false,
                'Yes',
                'No');
            return;
        }
        this.refModal.Alert(`Operation is not yet supported current display mode [${this.tableMode}]`);
    }

    handleNormalizeFetchData(tableData) {
        const { userId } = this.props;

        let data = tableData.map((x) => {
            x.ssnSet = NormalizeSsn(x, true);
            return x;
        });
        if (userId) {
            //Normalize UserUserRel entry to table entry
            data = tableData.map(x => {
                var user = x[ERelFields.RelatedUserEntity];
                user.RelId = x[ERelFields.Id];
                user[ERelFields.RelType] = x[ERelFields.RelType];
                return user;
            });
        }
        return data;
    }

    handleFormatUrl(page, pageSize, searchText, postData) {
        this.refAlert.hide();

        const { userId } = this.props;

        const urlParams = [
            `page=${page}`,
            `pageSize=${pageSize}`,
            `filter=${searchText || ''}`,
        ];

        const url = userId
            ? `${URL_PERSON}/${userId}/related`
            : `${URL_PERSON}`;
        return `${url}/paged?${urlParams.join('&')}`;
    }

    handleBeforeSaveTableCell(oldVal, newVal, row, col, done) {
        newVal = T.IsDefined(newVal) ? newVal : undefined;
        oldVal = T.IsDefined(oldVal) ? oldVal : undefined;
        if (oldVal === newVal) {
            done(true);
            return;
        }
        const { userId } = this.props;

        if (col.dataField === EPersonRelFields.RelType) {

            updateUserRel(this.refModal,
                userId, row.Id, newVal, (success) => {
                    done(success);
                });
            return;
        }
        done(true);
    }

    renderRowOptions(element) {
        const { onOpen, canDelete, disabled } = this.props;
        const options = [];

        if (!disabled && this.tableMode !== EPersonTableMode.MEMBER) {
            options.push(
                {
                    name: 'Edit',
                    onClick: () => this.handleNewEditButtonClick(element),
                    faIcon: faEdit
                });
        }

        if (T.IsFunc(onOpen)) {
            options.push(
                {
                    name: 'Open',
                    onClick: () => onOpen(element),
                    faIcon: faFolderOpen
                });
        }

        if (!disabled && (canDelete || this.tableMode !== EPersonTableMode.DEFAULT)) {
            options.push(
                {
                    name: 'Delete',
                    onClick: () => this.handleDeleteButtonClick(element),
                    faIcon: faTrash
                });
        }
        return renderOptionsCell(`${this.name}-${element.Id}`, options);
    }

    render() {
        const {
            selectRowOptions, buttons, data: members,
            disabled,
            validated, onNewIcon
        } = this.props;
        const {
            data, pageOptions, defaultSorted, cellEditOptions
        } = this.state;
        const memberView = this.tableMode === EPersonTableMode.MEMBER;
        const sId = `${this.name}_eTable`;

        return (
            <React.Fragment>
                <ComponentModal size="lg"
                    link={e => this.refModal = e} />
                <ComponentModal size="xl"
                    link={e => this.refModalXL = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <span>{this.name}|{this.tableMode}</span>
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={members || data}
                    disableLoadOnInit={this.props.disableLoadOnInit}
                    link={(c) => { this.refTable = c; }}
                    validated={validated}
                    disabled={disabled}
                    columns={this.columns}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    pagingOff={memberView}
                    defaultSorted={defaultSorted}
                    buttons={buttons}
                    selectRowOptions={selectRowOptions}
                    cellEditOptions={cellEditOptions}
                    onFormatUrl={memberView ? undefined : this.handleFormatUrl}
                    onNormalizeFetchData={this.handleNormalizeFetchData}
                    onNewIcon={onNewIcon}
                    onNew={this.handleNewEditButtonClick}
                    headerSection={this.props.headerSection}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
