import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import ComponentModal from '../general/ComponentModal';
import { fetchWebApi } from '../utils/ClientUtil';
import * as T from '../utils/TypeUtil';
import { displayError } from '../utils/ExceptionUtil';

import { EUserAddressFields as EFields } from './EntityData';

import {
    renderOptionsCell,
    displayAddressEditorDialog
} from './EntityEditorUtil';

import '../general/css/general.css';

import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { URL_ADDRESS as URL } from '../AppConstants';
import { sanitizeJsonObject } from './EntityUtil';
import BootAlert from '../general/BootAlert';
import { EElementTableColFilterType, ElementTablePageOptions, TableColSortEntry } from '../general/ElementTableUtil';

/**
 * @param name [optional] component name
 * @param disableLoadOnInit [optional]
 * @param onElementChanged [optional] raises when row data has changed
 * @param canDelete [optional] whether user can delete a row in the table
 */
export class AddressTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refModal = undefined;
        this.refAlert = undefined;
        this.refTable = undefined;
        this.name = this.props.name || 'tableAddress';

        this.renderOptions = this.renderOptions.bind(this);
        this.handleLoadTableData = this.handleLoadTableData.bind(this);
        this.handleNewEditButtonClick = this.handleNewEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);

        this.state = {
            data: [],
            pageOptions: new ElementTablePageOptions(),
            defaultSorted: [
                new TableColSortEntry(EFields.Address),
                new TableColSortEntry(EFields.Address2),
                new TableColSortEntry(EFields.City),
            ],
        };

        if (T.IsFunc(props.link)) {
            props.link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    componentWillUnmount() {
        this.mount = false;
    }

    componentDidMount() {
        this.mount = true;
    }

    componentDidUpdate(prevProps, prevState) {
    }

    getColumns() {
        const cols = [
            {
                text: EFields.Id,
                dataField: EFields.Id,
                hidden: true,
            },
            {
                text: EFields.Address,
                dataField: EFields.Address,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFields.Address2,
                dataField: EFields.Address2,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFields.Unit,
                dataField: EFields.Unit,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFields.City,
                dataField: EFields.City,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFields.State,
                dataField: EFields.State,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFields.Zip,
                dataField: EFields.Zip,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EFields.County,
                dataField: EFields.County,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: '',
                dataField: 'options',
                isDummyField: true,
                allowFilter: false,
                sort: false,
                formatter: (cellValue, row) => {
                    return this.renderOptions(row);
                },
            },
        ];

        return cols;
    }

    handleNewEditButtonClick(e) {
        displayAddressEditorDialog(this.refModal,
            e.Id,
            (updated) => {
                if (!updated || !this.mount) return;

                const { onElementChanged } = this.props;

                //reload the table in case changes affect sorting/filter
                this.refTable.reloadTable();

                if (T.IsDefined(onElementChanged)) {
                    onElementChanged(updated);
                }
            }
        );
    }

    handleDeleteButtonClick() {
        this.refModal.Alert('Operation is not yet supported');
    }

    handleLoadTableData() {

        this.refAlert.hide();

        const msg = 'Error retrieving addresses.';
        fetchWebApi(`${URL}/list`,
            {
                'Content-Type': 'application/json',
            })
            .then((result) => {
                //if (!this.mount) return;
                sanitizeJsonObject(result);
                this.setState({ data: result });
            })
            .catch((error) => {
                //if (!this.mount) return;
                displayError(this.refAlert, msg, error);
            });
    }

    renderOptions(element) {
        const { canDelete } = this.props;
        const options = [];

        options.push(
            {
                name: 'Edit',
                onClick: () => this.handleNewEditButtonClick(element),
                faIcon: faEdit
            });

        if (canDelete) {
            options.push(
                {
                    name: 'Delete',
                    onClick: () => this.handleDeleteButtonClick(element),
                    faIcon: faTrash
                });
        }
        return renderOptionsCell(`${this.name}-${element.Id}`, options);
    }

    render() {
        const { pageOptions, defaultSorted } = this.state;
        const sId = `${this.name}_eTable`;

        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModal = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={this.state.data}
                    disableLoadOnInit={this.props.disableLoadOnInit}
                    link={(c) => { this.refTable = c; }}
                    columns={this.getColumns()}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                    onLoadTableData={this.handleLoadTableData}
                    onNew={this.handleNewEditButtonClick}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
