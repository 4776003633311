import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    FormatElementTableCellDateTime, FormatElementTableCellTextArea,
    ElementTablePageOptions, EElementTableColFilterType,
    TableColSortEntry
} from '../general/ElementTableUtil';
import * as T from '../utils/TypeUtil';

import { ETaskCommentFields, ETaskCommentFieldLabels } from './EntityData';
import '../general/css/general.css';
import BootAlert from '../general/BootAlert';

/**
 * @param name [optional] component name
 * @param ref [optional]
 * @param sizePerPage [optional] number of rows per page
 */
export class TaskCommentTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refAlert = undefined;

        const { link, name, sizePerPage } = this.props;
        this.name = name || 'tableTaskNote';

        this.state = {
            data: [],
            pageOptions: new ElementTablePageOptions(false, sizePerPage),
            defaultSorted: [
                new TableColSortEntry(ETaskCommentFields.CreatedOn, true)
            ],
        };

        if (T.IsFunc(link)) {
            link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    componentDidMount() {
        this.mount = true;
    }
    componentWillUnmount() {
        this.mount = false;
    }
    componentDidUpdate(prevProps, prevState) {
        const { sizePerPage } = this.props;

        if (prevProps.sizePerPage !== sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            this.setState({ pageOptions: updatedPageOptions });
        }
    }

    getColumns() {
        const cols = [
            {
                text: ETaskCommentFields.Id,
                dataField: ETaskCommentFields.Id,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskCommentFields.Note,
                dataField: ETaskCommentFieldLabels.Note,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellTextArea,
                headerStyle: { width: '75%' },
            },
            {
                text: ETaskCommentFieldLabels.CreatedBy,
                dataField: ETaskCommentFields.CreatedBy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskCommentFieldLabels.CreatedOn,
                dataField: ETaskCommentFields.CreatedOn,
                formatter: FormatElementTableCellDateTime,
                headerStyle: { width: '13%' },
            }
        ];

        return cols;
    }

    render() {
        const { data } = this.props;
        const { pageOptions, defaultSorted } = this.state;
        const sId = `${this.name}_eTable`;

        return (
            <React.Fragment>
                <span>{this.name}</span>
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={data}
                    disableLoadOnInit={this.props.disableLoadOnInit}
                    link={(c) => { this.refTable = c; }}
                    columns={this.getColumns()}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
