import {
    faAngleDoubleDown, faAngleDoubleUp,
    faAngleDown, faAngleUp,
    faArrowDownAZ,
    faArrowDownZA,
    faPlus,
    faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { buildInputCheckBoxInlineRaw, buildInputSelectRaw } from "../utils/EditorInputUtil";
import { TableColSortEntry } from './ElementTableUtil';
import { IsArrayEmpty } from "../utils/TypeUtil";
import { renderOptionsCell } from "../user/EntityEditorUtil";
import ComponentModal from "./ComponentModal";
import { stringComparer } from "../utils/DataFormatUtil";

const TEXT_PRIMARY = `text-primary`;
const TEXT_DANGER = `text-danger`;
const TEXT_DISABLED = `text-secondary`;

export const copyTableColSortEntryList = (list) => {
    const result = [];
    if (list) {
        list.forEach(x => result.push(Object.assign(new TableColSortEntry(), x)));
    }
    return result;
}

export function ElementTableSortColumnList({ columns,
    defaultSortList,
    sortList,
    styles,
    inline,
    onChange
}) {
    const [sorts, setSorts] = useState(sortList || []);
    const refModal = useRef();
    const keyPrefix = 'eTable-sort-cols_';

    useEffect(() => {
        setSorts(sortList || [])
    }, [sortList]);

    const handleResetButtonClicked = () => {
        const list = copyTableColSortEntryList(defaultSortList);
        setSorts(list);
        onChange(list);
    };
    const handleAddButtonClicked = () => {

        const options = [];
        Object.keys(columns).forEach(colName => {
            if (!sorts.find(x => x.dataField === colName)) {
                options.push({
                    id: colName,
                    name: columns[colName] || colName
                });
            }
        });
        options.sort(stringComparer);

        let selectedColName = options[0].id;
        let isAscending = true;
        let isAppend = false;

        const dlg = refModal.current;
        dlg.ComponentDialog('Add Sort Column',
            <ColumnSelector columnOptions={options}
                isAscending={isAscending}
                onColumnChange={(colName) => selectedColName = colName}
                onOrderChange={(x) => isAscending = x}
                onIsAppendChange={(x) => isAppend = x}
            />,
            () => { //OK
                const list = [...sorts];
                if (isAppend) {
                    list.push(new TableColSortEntry(selectedColName, !isAscending));
                } else {
                    list.splice(0, 0, new TableColSortEntry(selectedColName, !isAscending));
                }

                setSorts(list);
                onChange(list);
                dlg.close();
            }
        )

    };
    const handleSortButtonClicked = (dataField) => {
        const list = [...sorts];

        const entry = list.find(x => x.dataField === dataField);
        entry.desc = !entry.desc;
        setSorts(list);
        onChange(list);
    };
    const handleDeleteButtonClicked = (dataField) => {
        const updated = sorts.filter(x => x.dataField !== dataField);
        const list = IsArrayEmpty(updated) ? copyTableColSortEntryList(defaultSortList) : updated;
        setSorts(list);
        onChange(list);
    };
    const swapColumn = (currentIdx, targetIdx) => {
        const list = [...sorts];
        const temp = list[currentIdx];
        list[currentIdx] = list[targetIdx];
        list[targetIdx] = temp;
        setSorts(list);
        onChange(list);
    };
    const moveColumn = (currentIdx, isStart) => {
        const list = [...sorts];
        const temp = list[currentIdx];
        list.splice(currentIdx, 1);
        if (isStart) {
            list.splice(0, 0, temp);
        } else {
            list.push(temp);
        }
        setSorts(list);
        onChange(list);
    };

    if (inline) {
        return (
            <div style={styles}>
                <ComponentModal link={(e) => refModal.current = e} />
                {
                    sorts.map((entry) => {
                        const { dataField, desc } = entry;
                        const label = columns[dataField] || dataField;

                        return (
                            <React.Fragment key={keyPrefix + dataField}>
                                <div key={`sort-${dataField}`}
                                    title={desc ? 'Z to A' : 'A to Z'}
                                    className={TEXT_PRIMARY}
                                    onClick={() => handleSortButtonClicked(dataField)}
                                >
                                    <span >{label}</span>
                                    <FontAwesomeIcon icon={desc ? faArrowDownZA : faArrowDownAZ}
                                        className='ms-1'
                                    />
                                </div>
                                <FontAwesomeIcon icon={faX}
                                    onClick={() => handleDeleteButtonClicked(dataField)}
                                    className={TEXT_DANGER + ' ms-1'}
                                />
                                <span className='ms-1 me-1 text-primary'>|</span>
                            </React.Fragment>
                        );
                    })
                }
                <FontAwesomeIcon icon={faPlus}
                    onClick={handleAddButtonClicked}
                    className={`me-1 ${TEXT_PRIMARY}`}
                    title='Add Column'
                />
            </div>
        );
    }

    const sortColumnCnt = sorts.length;
    const iLast = sortColumnCnt - 1;
    const columnCnt = Object.keys(columns).length;
    return (
        <React.Fragment>
            <ComponentModal link={(e) => refModal.current = e} />
            <div style={styles}>
                {
                    sorts.map((entry, iEntry) => {
                        const { dataField, desc } = entry;
                        const label = columns[dataField] || dataField;
                        const isTop = iEntry === 0;
                        const isLast = iEntry === iLast;
                        const textUp = isTop ? TEXT_DISABLED : TEXT_PRIMARY;
                        const textDown = isLast ? TEXT_DISABLED : TEXT_PRIMARY;
                        const colKey = `${keyPrefix}${dataField}`;
                        return (
                            <Row key={colKey} className='mb-2' >
                                <Col
                                    title={desc ? 'Z to A' : 'A to Z'}
                                    style={{ textAlign: 'right' }}
                                    className=' col-5'
                                >
                                    <label>{label}</label>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={desc ? faArrowDownZA : faArrowDownAZ}
                                        onClick={() => handleSortButtonClicked(dataField)}
                                        className='fa-2x ms-1'
                                    />
                                    <FontAwesomeIcon icon={faX}
                                        title='Delete'
                                        onClick={() => handleDeleteButtonClicked(dataField)}
                                        className={TEXT_DANGER + ' fa-2x ms-1'}
                                    />
                                    <FontAwesomeIcon icon={faAngleDoubleUp}
                                        title='Move to Top'
                                        onClick={() => moveColumn(iEntry, true)}
                                        className={textUp + ' fa-2x ms-1'}
                                        disabled={isTop}
                                    />
                                    <FontAwesomeIcon icon={faAngleUp}
                                        title='Move up'
                                        onClick={() => swapColumn(iEntry, iEntry - 1)}
                                        className={textUp + ' fa-2x ms-1'}
                                        disabled={isTop}
                                    />
                                    <FontAwesomeIcon icon={faAngleDown}
                                        title='Move down'
                                        onClick={() => swapColumn(iEntry, iEntry + 1)}
                                        className={textDown + ' fa-2x ms-1'}
                                        disabled={isLast}
                                    />
                                    <FontAwesomeIcon icon={faAngleDoubleDown}
                                        title='Move to Bottom'
                                        onClick={() => moveColumn(iEntry, false)}
                                        className={textDown + ' fa-2x ms-1 me-4'}
                                        disabled={isLast}
                                    />
                                </Col>
                            </Row>
                        );
                    })
                }
            </div>
            <div style={{ float: 'right' }}>
                {
                    renderOptionsCell(`colSort-btns`,
                        [
                            {
                                name: 'Reset',
                                onClick: handleResetButtonClicked
                            },
                            {
                                name: 'Add Column',
                                disabled: sortColumnCnt >= 5 || sortColumnCnt === columnCnt,
                                onClick: handleAddButtonClicked
                            },
                        ])
                }
            </div>
        </React.Fragment>
    );
}

const ColumnSelector = ({ columnOptions,
    isAscending,
    onColumnChange, onOrderChange, onIsAppendChange }) => {

    const [selectedColName, setSelectedColName] = useState(columnOptions[0].id);
    const [isAsc, setIsAsc] = useState(isAscending);
    const [isAppend, setIsAppend] = useState(true);
    const keyPrefix = 'col-selector_';

    return (
        <React.Fragment>
            <Row>
                <Col>
                    {
                        buildInputSelectRaw(keyPrefix, selectedColName, undefined,
                            {
                                name: 'dataField',
                                title: 'Column'
                            },
                            columnOptions,
                            (evt, field, val) => {
                                setSelectedColName(val);
                                onColumnChange(val);
                            },
                            false, //disabled
                            false, //addInvalidOption
                            true //sort
                        )
                    }</Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    {
                        buildInputCheckBoxInlineRaw(keyPrefix,
                            {
                                label: 'Ascending',
                                value: isAsc
                            },
                            (evt, field, val) => {
                                setIsAsc(val);
                                onOrderChange(val);
                            }
                        )
                    }
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    {
                        buildInputCheckBoxInlineRaw(keyPrefix,
                            {
                                label: 'Add To Bottom',
                                value: isAppend
                            },
                            (evt, field, val) => {
                                setIsAppend(val);
                                onIsAppendChange(val);
                            }
                        )
                    }
                </Col>
            </Row>
        </React.Fragment>
    );
}