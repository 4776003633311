import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    FormatElementTableCellDateTime, FormatElementTableCellTextArea,
    ElementTablePageOptions, EElementTableColFilterType,
    TableColSortEntry
} from '../general/ElementTableUtil';
import ComponentModal from '../general/ComponentModal';
import * as T from '../utils/TypeUtil';

import { ELogEntryFields, ELogEntryFieldLabels, EElementType } from './EntityData';
import '../general/css/general.css';
import { URL_LOG } from '../AppConstants';
import BootAlert from '../general/BootAlert';

/**
 * @param loginUserId ID of the login user
 * @param id [optional] ID of log entry parent (e.g. person, policy, task, address)
 * @param name [optional] component name
 * @param sizePerPage [optional] number of rows per page
 * @param disableLoadOnInit [optional]
 */
export class LogEntryTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refModal = undefined;
        this.refModalError = undefined;
        this.refAlert = undefined;

        this.handleFormatUrl = this.handleFormatUrl.bind(this);

        const { link, name, sizePerPage } = this.props;
        this.name = name || 'tableLog';

        this.state = {
            data: [],
            pageOptions: new ElementTablePageOptions(false, sizePerPage),
            defaultSorted: [
                new TableColSortEntry(ELogEntryFields.CreatedOn, true)
            ],
        };

        if (T.IsFunc(link)) {
            link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    componentDidMount() {
        this.mount = true;
    }
    componentWillUnmount() {
        this.mount = false;
    }
    componentDidUpdate(prevProps, prevState) {
        const { parentId, parentType, sizePerPage } = this.props;

        if (prevProps.sizePerPage !== sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            this.setState({ pageOptions: updatedPageOptions });
        }
        else if (parentId !== prevProps.parentId || parentType !== prevProps.parentType) {
            this.reloadTable();
        }
    }


    handleFormatUrl(page, pageSize, searchText, postData) {
        this.refAlert.hide();

        const { creatorId, parentId, parentType } = this.props;

        const urlParams = [
            `page=${page}`,
            `pageSize=${pageSize}`,
            `filter=${searchText || ''}`,
        ];
        if (T.IsDefined(creatorId)) urlParams.push(`loginId=${creatorId}`);
        if (parentType === EElementType.AppUser) urlParams.push(`affLoginId=${parentId}`);
        if (parentType === EElementType.User) urlParams.push(`affUserId=${parentId}`);
        if (parentType === EElementType.Policy) urlParams.push(`affPolicyId=${parentId}`);
        if (parentType === EElementType.Task) urlParams.push(`affTaskId=${parentId}`);
        if (parentType === EElementType.UserAddress) urlParams.push(`affAddressId=${parentId}`);

        return `${URL_LOG}/paged?${urlParams.join('&')}`;
    }

    getColumns() {
        const cols = [
            {
                text: ELogEntryFields.Id,
                dataField: ELogEntryFields.Id,
                hidden: true,
                alwaysHidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFieldLabels.Note,
                dataField: ELogEntryFields.Note,
                formatter: FormatElementTableCellTextArea,
                headerStyle: { width: '30%' },
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFields.Details,
                dataField: ELogEntryFieldLabels.Details,
                formatter: FormatElementTableCellTextArea,
                headerStyle: { width: '30%' },
                filter: EElementTableColFilterType.TEXT,
                sort: false,
            },
            {
                text: ELogEntryFieldLabels.TargetUserFullName,
                dataField: ELogEntryFields.TargetUserFullName,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFieldLabels.AffPolicyName,
                dataField: ELogEntryFields.AffPolicyName,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFieldLabels.AffPolicyAcaFfmId,
                dataField: ELogEntryFields.AffPolicyAcaFfmId,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFieldLabels.AffTaskName,
                dataField: ELogEntryFields.AffTaskName,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFieldLabels.AffAppUser,
                dataField: ELogEntryFields.AffAppUser,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFieldLabels.AffAddress,
                dataField: ELogEntryFields.AffAddress,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFieldLabels.CreatedBy,
                dataField: ELogEntryFields.CreatedBy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ELogEntryFieldLabels.CreatedOn,
                dataField: ELogEntryFields.CreatedOn,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateTime,
            }
        ];

        const { parentType } = this.props;
        let columns = cols;
        if (parentType === EElementType.AppUser) {
            columns = columns.filter((x) => x.dataField !== ELogEntryFields.AffAppUser);
        }
        if (parentType === EElementType.User) {
            columns = columns.filter((x) => x.dataField !== ELogEntryFields.TargetUserFullName);
        }
        if (parentType === EElementType.Policy) {
            columns = columns.filter(
                (x) => x.dataField !== ELogEntryFields.AffPolicyName
                    && x.dataField !== ELogEntryFields.AffPolicyAcaFfmId
            );
        }
        if (parentType === EElementType.Task) {
            columns = columns.filter((x) => x.dataField !== ELogEntryFields.AffTaskName);
        }
        if (parentType === EElementType.UserAddress) {
            columns = columns.filter((x) => x.dataField !== ELogEntryFields.AffAddress);
        }

        return columns;
    }

    render() {
        const { pageOptions, defaultSorted, data } = this.state;
        const sId = `${this.name}_eTable`;

        return (
            <React.Fragment>
                <ComponentModal size="xl" link={e => this.refModal = e} />
                <ComponentModal link={e => this.refModalError = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <span>{this.name}</span>
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={data}
                    disableLoadOnInit={this.props.disableLoadOnInit}
                    link={(c) => { this.refTable = c; }}
                    columns={this.getColumns()}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                    onFormatUrl={this.handleFormatUrl}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
