import React, { Component } from 'react';
import { Row, Col, InputGroup, Button, Spinner } from 'reactstrap';
import { CONTENT_TYPE_JSON, fetchWebApi } from '../utils/ClientUtil';
import { getExceptionMessage } from '../utils/ExceptionUtil';
import * as T from '../utils/TypeUtil';
import './css/general.css';
import './css/elementTable.css';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory, { dateFilter, multiSelectFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTable,
    faSync,
    faPlus,
    faFilterCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import ComponentModal from './ComponentModal';
import { sanitizeJsonObject } from '../user/EntityUtil';
import { EElementTableColFilterType, ElementTablePageOptions, SortElements, TableColFilterEntry } from './ElementTableUtil';
import { EColor } from '../utils/DataFormatUtil';
import ViewSettingSelector from './ViewSettingSelector';
import { ElementTableColumnToggleList } from './ElementTableColumnToggleList';
import { ElementTableSortColumns } from './ElementTableSortColumns';
import { v4 as uuid } from 'uuid';

const { SearchBar, ClearSearchButton } = Search;

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
}) => (
    //<ul className="btn-group" role="group">
    <ul className='elementTablePageSizeOptions pagination'>
        {options.map((option) => {
            const isSelect = currSizePerPage === `${option.page}`;
            return (
                <li
                    key={option.text}
                    onClick={() => onSizePerPageChange(option.page)}
                    className={`btn btn-sm elementTablePageSizeItem ${isSelect ? 'active' : ''}`}
                >
                    {option.text}
                </li>
            );
        })}
    </ul>
);
const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        {from} to {to} of {size}
    </span>
);
const defaultPageOptions = {
    page: 1,
    sizePerPage: 5,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    paginationTotalRenderer,
    sizePerPageRenderer,
    sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '40', value: 40 },
    ],
};


/**
 * @param {string} name [optional] component name
 * @param columns table column definitions
 * @param buttons [optional] additional button to display on the top left panel
 * @param {ElementTablePageOptions} pageOptions [optional] 
 * @param {false} pagingOff [optional] whether to enable pagination
 * @param defaultSorted [optional] 
 * @param globalFilter [optional]
 * @param {Array<TableColFilterEntry>} defaultFilters [optional]
 * @param onNewIcon [optional]
 * @param onNew [optional] raise when button to create new entry is clicked
 * @param onLoadTableData [optional] raise when fetch all table data,
 * used when onFormatUrl is not set
 * @param onFormatUrl [optional] raise to get the URL to use for remote pagination
 * @param onNormalizeFetchData [optional] raise to normalize server data received via onFormatUrl
 * @param {bool} getOnly [optional] Default is 'POST', used in conjunction remote pagination
 * @param onPageLoaded [optional] raise when remote pagination is completed
 */
export class ElementTable extends Component {
    constructor(props) {
        super(props);
        this.getTablePageOptions = this.getTablePageOptions.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.handleViewColumns = this.handleViewColumns.bind(this);
        this.loadTablePageData = this.loadTablePageData.bind(this);
        this.resetTableFilter = this.resetTableFilter.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.getDefaultColFilterDef = this.getDefaultColFilterDef.bind(this);
        this.handleSaveSettings = this.handleSaveSettings.bind(this);
        this.getColSettings = this.getColSettings.bind(this);
        this.handleSortColumnListChanged = this.handleSortColumnListChanged.bind(this);

        this.refModal = undefined;
        this.refModalError = undefined;
        this.refColFilters = {};
        this.colIdx = 0;

        const {
            name,
            data,
            columns,
            disabled,
            pageOptions,
            defaultSorted
        } = this.props;

        this.name = name || 'tableElement';

        const { editables, sortables, visibles } = this.getColSettings(columns);
        const cols = this.createTableColumns(columns);

        this.colVisibleDefaultMap = visibles;
        this.colEditableDefaultMap = editables;
        this.colSortables = sortables;
        this.canSort = Object.keys(sortables).length > 0;
        this.columns = cols;

        const colSorts = defaultSorted || [];
        let staticData = data;
        if (T.IsArrayNonEmpty(colSorts) && T.IsArrayNonEmpty(data)) {

            staticData = SortElements([...staticData], colSorts);
        }

        this.state = {
            loading: false,
            loadError: undefined,
            colFilters: undefined,
            colSorts,
            tableCols: disabled
                ? cols.map(x => ({ ...x, editable: false }))
                : cols,
            staticData,
            tableUid: uuid(),
            pageOptions: this.getTablePageOptions(pageOptions),
        };

        if (T.IsFunc(props.link)) { props.link(this); }
    }

    getColSettings(cols) {

        const visibles = {};
        const editables = {};
        const sortables = {};
        cols.forEach(col => {
            const {
                dataField,
                text: lb,
                hidden, alwaysHidden, isDummyField,
                editable,
                sort
            } = col;

            editables[dataField] = T.DefaultBool(editable, false) || editable;
            visibles[dataField] = !(hidden || alwaysHidden || isDummyField);
            if (T.DefaultBool(sort, true)) {
                sortables[dataField] = lb;
            }
        });
        return { visibles, editables, sortables };
    }

    createTableColumns(columns) {
        const getCellClasses = (col, row) => {
            const { disabled, validated } = this.props;
            const { dataField, classes: colClasses, editable: canEdit } = col;
            const { errors } = row;
            const val = row[dataField];
            const editable = !disabled && (T.IsFunc(canEdit) ? canEdit(val, row) : canEdit);

            if (editable) { //column is editable

                if (T.DefaultBool(validated, false) //show validation error
                    && T.IsDefined(errors)          //valid error table
                ) {
                    if (T.IsDefined(errors[dataField])) {

                        return `${colClasses || ''} invalid-cell`;
                    }
                    return `${colClasses || ''} valid-cell`;
                }
                return 'edit-cell';
            }
            return colClasses;
        };

        const cols = columns.map((col, iCol) => {
            return {
                allowFilter: true,
                headerAlign: 'center',
                editable: false,
                //headerClasses: 'bg-info',
                classes: (cell, row, rowIndex, colIndex) => {
                    return getCellClasses(col, row)
                },
                ...col,
                sort: false,
                ...this.getDefaultColFilterDef(col),
                formatExtraData: () => {
                    return `${this.name}_${iCol}-`
                }
            };
        });
        return cols;
    }

    componentDidMount() {
        const { disableLoadOnInit } = this.props;

        if (!T.DefaultBool(disableLoadOnInit, false)) {
            this.reloadTable();
        }
    }
    componentDidUpdate(prevProps) {
        const { disabled, data } = this.props;
        if (disabled !== prevProps.disabled) {

            const { tableCols } = this.state;
            this.setState({
                tableCols: tableCols.map(x => ({
                    ...x,
                    editable: !disabled && this.colEditableDefaultMap[x.dataField]
                }))
            })
        }
        if (data !== prevProps.data) {
            const { colSorts } = this.state;
            let staticData = data;
            if (T.IsArrayNonEmpty(colSorts) && T.IsArrayNonEmpty(data)) {

                staticData = SortElements([...staticData], colSorts);
            }
            this.setState({
                staticData,
                tableUid: uuid(),
            });
        }
    }

    getDefaultColFilterDef = (col) => {
        const { filter: filterType, dataField } = col;
        let filter = undefined;

        const getFilter = (f) => {
            this.refColFilters[dataField] = {
                filterType,
                filter: f
            };
        };
        const id = `${this.name}_col-${this.colIdx++}`;
        const placeholder = '<search>';

        if (T.IsDefined(filterType)) {
            switch (filterType) {
                case EElementTableColFilterType.TEXT:
                    filter = textFilter({ getFilter, id, placeholder })
                    break;
                case EElementTableColFilterType.SELECT:
                    filter = selectFilter({ ...{ getFilter, id, placeholder }, ...col.filterOptions });
                    break;
                case EElementTableColFilterType.MULTI_SELECT:
                    filter = multiSelectFilter({ ...{ getFilter, id, placeholder }, ...col.filterOptions });
                    break;
                case EElementTableColFilterType.DATE:
                    filter = dateFilter({ getFilter, id, placeholder });
                    break;
                default:
                    break;
            }
        }

        return filter ? { filter } : {};
    }

    reloadTable() {
        const { onLoadTableData, onFormatUrl } = this.props;
        if (T.IsFunc(onLoadTableData)) {

            this.props.onLoadTableData();
        }
        else if (T.IsFunc(onFormatUrl)) {
            const { searchText, pageOptions, colSorts, colFilters } = this.state;
            const { page, sizePerPage } = pageOptions;
            this.loadTablePageData(page, sizePerPage, searchText, colSorts, colFilters);
        }
        else {
            console.log(this.name + " onLoadTableData/onFormatUrl NOT FOUND");
            const { colSorts, staticData } = this.state;
            if (T.IsArrayNonEmpty(colSorts) && T.IsArrayNonEmpty(staticData)) {

                const sortItems = SortElements(staticData, colSorts);
                this.setState({
                    staticData: sortItems,
                    tableUid: uuid(),
                });
            }
        }
    }

    resetTableFilter() {
        const { colFilters } = this.state;
        if (T.IsArrayNonEmpty(colFilters)) {

            //clear current column filters
            colFilters.forEach((col) => {

                const refFilter = this.refColFilters[col.dataField];
                if (refFilter) {
                    switch (refFilter.filterType) {
                        case EElementTableColFilterType.MULTI_SELECT:
                            refFilter.filter([]);
                            break;
                        default:
                            refFilter.filter('');
                            break;
                    }
                }
            });

            //reset state
            const { searchText, pageOptions, colSorts } = this.state;
            const { page, sizePerPage } = pageOptions;

            this.loadTablePageData(page, sizePerPage, searchText, colSorts, undefined); //reset filter
        }
    }

    /**
     * @param {ElementTablePageOptions} changedOptions
     */
    getTablePageOptions(changedOptions) {

        const { pagingOff } = this.props;
        if (T.DefaultBool(pagingOff, false)) return {};

        return {
            ...defaultPageOptions,
            ...(changedOptions || {}),
        };
    }

    /**
     * Method to handle the manual fetching of data from the server when in 'lazy' mode.
     */
    handleTableChange(type, params) {
        console.log(`${this.name}[${type}]`, params);

        const {
            page, sizePerPage,
            searchText,
            filters
        } = params || {};
        const { colSorts } = this.state;

        const pageFilters = [];
        const filterProps = Object.keys(filters || {});
        if (T.IsArrayNonEmpty(filterProps)) {
            for (const field of filterProps) {
                const item = filters[field];
                pageFilters.push(new TableColFilterEntry(field, item.filterVal));
            }
        }

        this.loadTablePageData(page, sizePerPage, searchText, colSorts, pageFilters); //react table changed
    }

    /**
     * Load data from server
     */
    loadTablePageData(page, pageSize, searchText, sorts, filters) {
        console.log(`loadTablePageData(${this.name})...`);
        const {
            getOnly,
            onFormatUrl,
            onNormalizeFetchData,
            defaultFilters,
            onPageLoaded
        } = this.props;

        const tableColFilters = T.IsArrayNonEmpty(filters)
            ? filters
            : T.IsArrayNonEmpty(defaultFilters)
                ? defaultFilters : undefined;

        const postData = {
            filters: tableColFilters,
            sorts: T.IsArrayNonEmpty(sorts) ? sorts : undefined
        };
        const url = onFormatUrl(page, pageSize, searchText, postData);

        const body = {};
        if (!T.DefaultBool(getOnly, false)) {
            body.method = 'POST';
            body.body = JSON.stringify(postData);
        }

        const newState = {
            loading: true,
            loadError: undefined,
            searchText,
            colFilters: tableColFilters,
        }
        this.setState(newState, () => {
            fetchWebApi(url, body, { 'Content-Type': CONTENT_TYPE_JSON })
                .then(result => {
                    sanitizeJsonObject(result);

                    const { totalPage, totalSize, data, metadata } = result;
                    const tableData = T.IsFunc(onNormalizeFetchData) ? onNormalizeFetchData(data, metadata) : data;
                    this.setState({
                        loading: false,
                        pageOptions: new ElementTablePageOptions(true, pageSize, page, totalPage, totalSize),
                        data: tableData
                    }, onPageLoaded);
                })
                .catch(error => {
                    console.error('ElementTable.handleFetchData error: ', url, error);

                    const msg = 'An error prevented the requested data from being loaded.';
                    getExceptionMessage(msg,
                        error,
                        (errorTxt) => {
                            this.setState({
                                loading: false,
                                loadError: errorTxt,
                            }, onPageLoaded);
                        }
                    );
                });
        });
    }

    handleSortColumnListChanged(list) {
        this.setState({ colSorts: list }, this.reloadTable);
    }

    handleViewColumns(columnToggleProps) {
        if (!this.refModal) return;

        let colVisibleTable = { ...columnToggleProps.toggles };
        this.refModal.ComponentDialog('Table Columns',
            <ElementTableColumnToggleList {...columnToggleProps}
                defaultToggles={this.colVisibleDefaultMap}
                onChange={(updated) => colVisibleTable = updated}
            />,
            () => { // OK
                this.refModal.close();
                const { tableCols: cols } = this.state;
                this.setState({
                    tableCols: cols.map(x => (
                        {
                            ...x,
                            hidden: !colVisibleTable[x.dataField]
                        }
                    ))
                });
            },
            false,      // Cancel
            '',         // Apply label
            'Close',    // OK label
            ''          // Cancel label
        );
    }

    handleSaveSettings() {
        const { colSorts, colFilters } = this.state;
        var settings = {
            filters: colFilters,
            sorts: colSorts,
        };
        return settings;
    }

    renderLeftControlPanel(canFilterCols, tkProvider) {
        const { columnToggleProps } = tkProvider;
        const {
            disabled,
            onNew, onNewIcon,
            buttons,
            onLoadTableData,
            onFormatUrl,
        } = this.props;
        const cols = this.columns;
        const visibleCols = cols.filter(x => !x.isDummyField && !x.alwaysHidden);

        return (
            <div className='btn-group' style={{ position: 'absolute', bottom: '2px' }}>
                {
                    (visibleCols.length > 5) &&
                    <Button className="d-inline-block mr-1 me-1"
                        onClick={() => { this.handleViewColumns(columnToggleProps) }}
                        size="sm" title="Show/hide columns" color={EColor.Primary}
                    >
                        <FontAwesomeIcon icon={faTable} />
                    </Button>
                }
                {
                    canFilterCols &&
                    <Button className="d-inline-block mr-1 me-1"
                        onClick={() => {
                            this.resetTableFilter();
                        }}
                        size="sm"
                        title="Clear all column filters"
                        color={EColor.Primary}
                    >
                        <FontAwesomeIcon icon={faFilterCircleXmark} />
                    </Button>
                }
                {
                    (T.IsFunc(onFormatUrl) || T.IsFunc(onLoadTableData)) &&
                    <Button className="d-inline-block mr-1 me-1"
                        onClick={() => {
                            this.reloadTable();
                        }}
                        size="sm"
                        title="Reload table dataa"
                        color={EColor.Primary}
                    >
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                }
                {
                    T.IsFunc(onNew) && !disabled &&
                    <Button className="d-inline-block mr-1 me-1"
                        onClick={() => onNew()}
                        size="sm"
                        title="Add new table entry"
                        color={EColor.Primary}
                    >
                        <FontAwesomeIcon icon={T.IsDefined(onNewIcon) ? onNewIcon : faPlus} />
                    </Button>
                }
                {buttons}
            </div>
        );
    }

    renderSearch(props) {
        return (
            <InputGroup>
                <SearchBar {...props} tableId={`${this.name}_search`} />
                <ClearSearchButton className='elemenTableClearSearchBtn btn-primary btn-sm'
                    {...props}
                />
            </InputGroup>
        );
    }


    render() {
        const {
            keyField, defaultSorted,
            selectRowOptions, pagingOff, cellEditOptions,
            headerSection,
            canSaveFilter,
            disabled
        } = this.props;
        const {
            loading,
            loadError,
            pageOptions,
            data: fetchData,
            staticData,
            tableUid,
            tableCols,
            colSorts
        } = this.state;
        const tablePageOptions = this.getTablePageOptions(pageOptions);

        const canFilter = this.columns.filter(x => x.allowFilter).length > 0;

        const cellEdit = T.IsDefined(cellEditOptions) ? cellEditFactory(cellEditOptions) : undefined;

        const tableProps = {
            remote: T.DefaultBool(tablePageOptions.remote, false),
            keyField,
            key: `${this.name}_bootstrapTable`,
            striped: true,
            hover: true,
            condensed: true,
            // bootstrap4: true,
            // ref: "table",
            // data,
            // columns: tableCols,
            headerClasses: 'table-primary',
            selectRow: selectRowOptions,
            filter: filterFactory(),
            cellEdit: cellEdit,
            noDataIndication: () => {
                if (loading) {
                    return (
                        <React.Fragment>
                            <Spinner />
                            <span className='text-primary'>Loading...</span>
                        </React.Fragment>
                    );
                }
                if (loadError) return <span className='text-danger'>{loadError}</span>;
                return <span className='text-primary'>No elements found</span>;
            },
            pagination: pagingOff ? undefined : paginationFactory(tablePageOptions),
            onTableChange: this.handleTableChange
        };

        return (
            <ToolkitProvider
                bootstrap4={true}
                keyField={keyField}
                key={`${this.name}_bootstrapTableToolkit_${tableUid}`}
                data={fetchData || staticData || []}
                columns={tableCols}
                search
                columnToggle
            >
                {
                    tkProps => {
                        const myProps = { ...tableProps, ...tkProps.baseProps };
                        return (
                            <React.Fragment>
                                <ComponentModal link={(e) => this.refModal = e} />
                                <Row>
                                    <Col className='col-sm-3' style={{ position: 'relative' }}>
                                        {this.renderLeftControlPanel(canFilter, tkProps)}
                                    </Col>
                                    <Col className='col-sm-6'>
                                        {
                                            headerSection &&
                                            <React.Fragment>{headerSection}</React.Fragment>
                                        }
                                    </Col>
                                    <Col className='col-sm-3'>
                                        {
                                            canFilter &&
                                            <Row>
                                                <Col>{this.renderSearch(tkProps.searchProps)}</Col>
                                            </Row>
                                        }
                                        {
                                            canSaveFilter &&
                                            <Row>
                                                <Col>
                                                    <ViewSettingSelector name={this.name}
                                                        onSaveSettings={() => this.handleSaveSettings()}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                {
                                    cellEdit && !disabled &&
                                    <Row>
                                        <Col>
                                            <span className='text-primary'>
                                                To activate a button within a table cell: right-click then left-click on the button
                                            </span>
                                        </Col>
                                    </Row>
                                }
                                {
                                    this.canSort &&
                                    <ElementTableSortColumns defaultSorted={defaultSorted}
                                        sortableColumns={this.colSortables}
                                        sortColumns={colSorts}
                                        visible
                                        onChange={(list) => this.handleSortColumnListChanged(list)}
                                    />
                                }
                                <BootstrapTable {...myProps}
                                ></BootstrapTable>
                            </React.Fragment>
                        );
                    }
                }
            </ToolkitProvider>
        );
    }
}
