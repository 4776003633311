import React, { Component } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import ComponentModal from '../general/ComponentModal';
import {
    buildInputString, buildInputSelect,
    checkRequired,
    buildInputZip5,
    renderButton,
    renderFetchStatus,
    buildInputAutoComplete
} from '../utils/EditorInputUtil';
import { fetchWebApi } from '../utils/ClientUtil';
import { displayError, getExceptionMessage } from '../utils/ExceptionUtil';
import * as T from '../utils/TypeUtil';
import '../general/css/general.css';

import { EUserAddressFields as EFields, UserAddressFieldInfos as Fields } from './EntityData';
import { fetchCityState, sanitizeJsonObject } from './EntityUtil';
import { URL_ADDRESS as URL } from '../AppConstants';
import { faEraser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { EAddressTypeOptions } from '../AppEnums';

export class AddressEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loadingStates: false,
            loadingStatesError: undefined,
            loadingCityState: false,
            countyOptions: [],
            data: props.data || {},
        };

        this.mount = false;
        this.refModal = undefined;
        this.name = this.props.name || 'editorAddress';

        if (T.IsFunc(props.link)) {
            props.link(this);
        }

        this.onInputChanged = this.onInputChanged.bind(this);
        this.validateData = this.validateData.bind(this);
        this.reloadCityState = this.reloadCityState.bind(this);
        this.handleClearButtonClicked = this.handleClearButtonClicked.bind(this);
    }

    componentWillUnmount() {
        this.mount = false;
    }

    componentDidMount() {
        this.mount = true;

        const { data } = this.props;
        this.fetchStateOptions(() => {
            if (!this.fetchElementData()) {

                const zip = data[EFields.Zip];
                if (zip) {
                    this.reloadCityState(zip, true);
                }
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        if (prevProps.data !== data) {
            this.setState({ data }, () => {

                const zip = data[EFields.Zip];
                if (zip) {
                    this.reloadCityState(zip, true);
                }
            });
        }
    }

    fetchStateOptions(callback) {
        this.setState({ loadingStates: true, loadingStatesError: undefined });

        const msg = 'Error retrieving available states.';
        fetchWebApi(`${URL}/state`)
            .then((result) => {
                if (!this.mount) return;

                sanitizeJsonObject(result);
                this.setState({
                    stateOptions: result,
                }, () => {
                    if (T.IsFunc(callback)) {
                        callback();
                    }
                });
            })
            .catch((error) => {
                if (!this.mount) return;
                getExceptionMessage(msg, error, (errorMsg) => {
                    this.setState({ loadingStatesError: errorMsg });
                })
            })
            .finally(() => {
                this.setState({ loadingStates: false });
            });
    }

    fetchElementData() {
        const { id, isNew } = this.props;
        if (!isNew && !T.IsDefined(id)) return false;

        this.setState({ loading: true });

        const url = T.IsDefined(id) && id !== T.EmptyGuid
            ? `${URL}/${id}`
            : `${URL}/create`;
        const msg = 'Error retrieving address data.';
        fetchWebApi(url)
            .then((result) => {
                if (!this.mount) return;

                sanitizeJsonObject(result);
                this.setState({
                    data: result,
                }, () => {
                    const zip = result[EFields.Zip];
                    if (zip) {
                        this.reloadCityState(zip, true);
                    }
                });
            })
            .catch((error) => {
                if (!this.mount) return;
                displayError(this.refModal, msg, error);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
        return true;
    }

    reloadCityState(zip5, countyOnly = false) {
        if (T.stringIsNullOrEmpty(zip5)) return;

        const val = zip5.trim();
        if (val.length !== 5) return;

        this.setState({ loadingCityState: true });
        fetchCityState(val, (succeed, city, state, county) => {
            if (!this.mount) return;

            if (succeed) {
                this.setState({ countyOptions: county }, () => {

                    if (!countyOnly) {

                        this.onInputChanged(this, EFields.City, city, () => {
                            this.onInputChanged(this, EFields.State, state, () => {
                                const s = T.IsArrayNonEmpty(county) ? county[0] : undefined;
                                this.onInputChanged(this, EFields.County, s);
                            });
                        });
                    }
                });
            }
            this.setState({ loadingCityState: false });
        })
    }

    validateData() {
        this.setState({ errors: {} }); //Clear all errors

        const { optional, typeVisible } = this.props;
        const { data } = this.state;
        const postData = { ...data };
        const errors = {};

        // validate
        var emptyFieldCnt = 0;

        const keys = Object.keys(Fields);
        keys.forEach(field => {
            const info = Fields[field];

            const fieldVal = postData[info.name];
            if (!T.IsDefined(fieldVal)) {
                emptyFieldCnt++;
            }

            const fieldValid = !info.required
                || checkRequired(errors, postData, field);
            if (fieldValid) {
            }
        });

        if (!typeVisible) {
            // Type is set by default
            emptyFieldCnt++;
        }
        if (optional && (keys.length === emptyFieldCnt)) {
            return postData;
        }
        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors });
            return undefined;
        }
        return postData;
    }

    onSave(callback) {

        const postData = this.validateData();
        if (!postData) {
            return;
        }

        //POST
        const msg = 'Error saving address data.';
        fetchWebApi(URL,
            {
                method: 'POST',
                body: JSON.stringify(postData)
            },
            { 'Content-Type': 'application/json' })
            .then((result) => {
                //if (!this.mount) return;

                sanitizeJsonObject(result);
                this.setState({ data: result });
                this.refModal.Success('Successfully saved.', '',
                    () => {
                        this.refModal.close();
                        if (T.IsFunc(callback)) {
                            callback(result);
                        }
                    });
            })
            .catch((error) => {
                //if (!this.mount) return;
                displayError(this.refModal, msg, error);
            });
    }

    onInputChanged(evt, field, fieldValue, callback) {
        const { data } = this.state;
        const value = field !== EFields.Zip
            ? fieldValue
            : T.stringIsNullOrEmpty(fieldValue) ? '' : fieldValue;
        if (data[field] === value) {
            if (T.IsFunc(callback)) callback();
            return;
        }

        const newData = {
            ...data,
            ...{ [field]: value }
        };

        this.setState({
            data: newData
        }, () => {
            if (T.IsFunc(callback)) callback();

            switch (field) {
                case EFields.Zip:
                    if (value) {
                        this.reloadCityState(value);
                    }
                    break;
                default:
                    break;
            }
            const { onChanged: h } = this.props;
            if (T.IsFunc(h)) h(newData);
        });
    }

    handleClearButtonClicked() {
        const { data } = this.state;
        data[EFields.Address] = undefined;
        data[EFields.Address2] = undefined;
        data[EFields.Unit] = undefined;
        data[EFields.City] = undefined;
        data[EFields.Zip] = undefined;
        data[EFields.State] = undefined;
        data[EFields.County] = undefined;
        this.setState({
            data: data
        });
    }

    render() {
        const {
            type, disabled: readOnly, typeVisible,
            onDeleteButtonClicked
        } = this.props;
        const {
            data, errors, stateOptions,
            loading,
            loadingStates, loadingStatesError,
            loadingCityState, countyOptions
        } = this.state;
        const disabled = readOnly || loading || loadingStates;

        const keyPrefix = `${this.name}-`;
        const displayType = T.DefaultBool(typeVisible, false);

        var addressField = Fields[EFields.Address];
        if (!displayType && T.IsDefined(type)) {
            addressField = { ...addressField, ...{ label: `${type} ${addressField.label}` } };
        }
        const topRowClassName = typeVisible ? 'mb-2' : '';
        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModal = e} />
                {loading && <Spinner />}
                {
                    (displayType || !disabled) &&
                    <Row className={topRowClassName}>
                        <Col className='col-sm-5'>
                            {
                                typeVisible &&
                                buildInputSelect(keyPrefix, data, errors, Fields[EFields.Type],
                                    EAddressTypeOptions,
                                    this.onInputChanged, disabled,
                                    true)
                            }
                        </Col>
                        <Col style={{ textAlign: 'end' }}>
                            {
                                renderButton(`${keyPrefix}btnClear`, 'Clear',
                                    () => { this.handleClearButtonClicked() },
                                    disabled, '', faEraser)
                            }
                            {
                                T.IsFunc(onDeleteButtonClicked)
                                && renderButton(`${keyPrefix}btnDel`, 'Delete',
                                    () => { onDeleteButtonClicked() },
                                    disabled, '', faXmark)
                            }
                        </Col>
                    </Row>
                }
                <Row className='mb-2'>
                    <Col className='col-sm-5'>
                        {
                            buildInputString(keyPrefix, data, errors, addressField,
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-5'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.Address2],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.Unit],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                </Row>
                <Row className=''>
                    <Col className='col-sm-3'>
                        {
                            buildInputZip5(keyPrefix, data, errors, Fields[EFields.Zip],
                                this.onInputChanged, disabled || loadingCityState)
                        }
                    </Col>
                    <Col className='col-sm-3'>
                        {
                            buildInputAutoComplete(keyPrefix, data, errors, Fields[EFields.County],
                                countyOptions,
                                this.onInputChanged, disabled)
                        }
                        {renderFetchStatus(loadingCityState)}
                    </Col>
                    <Col className='col-sm-3'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.City],
                                this.onInputChanged, disabled)
                        }
                        {renderFetchStatus(loadingCityState)}
                    </Col>
                    <Col className='col-sm-3'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.State],
                                stateOptions || [],
                                this.onInputChanged, disabled, true)
                        }
                        {renderFetchStatus(loadingStates || loadingCityState, loadingStatesError)}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
