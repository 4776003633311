import React from 'react';
import {
    Row,
    Button,
} from 'reactstrap';

import * as T from '../utils/TypeUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { EColor } from '../utils/DataFormatUtil';

/**
 * Spinner component with preset font awesome spin class set.
 * @param icon Default is 'faSpinner'
 * @param scale Default is 5
 * @param color Default is 'dark'
 * @param magin Default is 'mb-3'
 * @param cancel Func<in evt>
 */
export default class Spinner extends React.Component {
    constructor(props) {
        super(props);

        const { link, icon, scale, color, margin } = this.props;

        this.state =
        {
            icon: icon || faSpinner,
            scale: scale || 5,
            color: color || EColor.Dark,
            margin: margin || 'mb-3'
        };

        if (T.IsFunc(link)) { link(this); }
    }

    getScaleClass(scale) { return 'fa-' + scale + 'x'; }

    render() {
        let cancelButton = (<Row></Row>);
        const { cancel } = this.props;

        // If a cancel function was set add a button element to execute it
        if (T.IsFunc(cancel)) {
            cancelButton = (
                <Row>
                    <Button onClick={cancel} className="mx-auto">Cancel</Button>
                </Row>
            );
        }

        return (
            <div>
                <Row>
                    <FontAwesomeIcon icon={this.state.icon}
                        className={
                            this.classPrefix +
                            ' ' + this.getScaleClass(this.state.scale) +
                            ' text-' + this.state.color + ' mx-auto ' + this.state.margin
                        }
                        color={EColor.Danger}
                    />
                </Row>
                {cancelButton}
            </div>
        );
    }
}