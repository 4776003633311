import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { AcaActivityStatusEditor } from './user/AcaActivityStatusEditor';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: undefined
        };
        this.populateState = this.populateState.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        try {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
            this.setState({
                isAuthenticated,
                userName: user && user.name,
            });
        } catch (error) {
            console.log('populateState', error);
            this.setState({
                isAuthenticated: false,
                userName: undefined
            });
        }
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        let msg = 'Welcome';
        if (isAuthenticated) {
            msg += ` ${userName}`;
        }
        return (
            <React.Fragment>
                <h1>{msg}</h1>
                <hr />
                <AcaActivityStatusEditor userName={userName}/>
            </React.Fragment>
        );
    }
}
