import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { EEvent, publish } from "../events"

const HUB_ELEMENT = '/wsElement';
const EWsElementMethod = {
  ON_CHANGED: 'elementChanged',
  ON_DELETED: 'elementDeleted',
  ON_IMPORTED: 'elementImported'
}

/**
 * SIGNALR
 */
export class WsConnection {
  
  _initialized = false;
  _connElement = null;

  start() {
    if (this._initialized) return;
    this.initialized = true;

    const conn = new HubConnectionBuilder()
      .withUrl(HUB_ELEMENT)
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    console.log(`SignalR[${HUB_ELEMENT}]: registering handler... ${EWsElementMethod.ON_CHANGED}`);
    conn.on(EWsElementMethod.ON_CHANGED, (eType, eId) => {
      console.log(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]: ${EWsElementMethod.ON_CHANGED} [type=${eType}, id=${eId}]`);
      publish(EEvent.ELEMENT_CHANGED, { type: eType, id: eId });
    });
    conn.on(EWsElementMethod.ON_DELETED, (eType, eId) => {
      console.log(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]: ${EWsElementMethod.ON_DELETED} [type=${eType}, id=${eId}]`);
      publish(EEvent.ELEMENT_CHANGED, { type: eType, id: eId, isDeleted: true });
    });
    conn.on(EWsElementMethod.ON_IMPORTED, (eType, eId) => {
      console.log(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]: ${EWsElementMethod.ON_IMPORTED} [type=${eType}]`);
      publish(EEvent.ELEMENT_IMPORTED, { type: eType });
    });
    conn.onclose((error) => {
      console.log(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]:  closed`, error);
    });
    conn.onreconnecting((error) => {
      console.log(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]:  reconnecting`, error);
    });
    conn.onreconnected((id) => {
      if (id === conn.connectionId) {
        console.log(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]:  reconnected`);
      }
    });

    console.log(`SignalR[${HUB_ELEMENT}]:  starting...`);
    conn.start()
      .then(() => {
        console.info(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]:  started`)
      }).catch((error) => {
        console.error(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]:  Error starting SignalR connection`, error);
      });
    this._connElement = conn;
  }

  async stop() {
    if (!this._initialized) return;
    this._initialized = true;

    const conn = this._connElement;
    this._connElement = undefined;

    if (conn) {
      const { state } = conn;
      switch (state) {
        case HubConnectionState.Connected:
        case HubConnectionState.Reconnecting:
          console.log(`SignalR[${HUB_ELEMENT}|${conn.connectionId}]:  stopping...`);
          conn.stop()
            .then(() => {
              console.info(`SignalR[${HUB_ELEMENT}|${conn.connectionId}] stopped`)
            }).catch((error) => {
              console.error(`SignalR[${HUB_ELEMENT}|${conn.connectionId}] Error stopping SignalR connection`, error);
            });
          break;
        default:
          break;
      }
    }
  }

  static get instance() { return wsConnection }
}

const wsConnection = new WsConnection();

export default wsConnection;
