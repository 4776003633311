import { fetchWebApi as Api } from './ClientUtil';
import * as T from './TypeUtil';
import { getExceptionMessage } from './ExceptionUtil';

// Retreives the users's privileges and stores them in state.
// If an error occurs, it is logged to the console
export const GetUserPrivileges = function GetUserPrivileges(callBackFunction) {
    let url = 'api/roles/privileges';
    Api(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
        .then((response) => {
            if (T.IsFunc(callBackFunction)) {
                if (response && response.Privileges) {
                    callBackFunction(response.Privileges);
                } else {
                    callBackFunction();
                }
            }
        })
        .catch((error) => {
            const msg = 'Could not communicate with server to retrieve user privileges';
            getExceptionMessage(msg, error, () => {
                if (T.IsFunc(callBackFunction)) {
                    callBackFunction();
                }
            });
        });
};

// Checks to see if the user has a specific privilege
export const UserHasPrivilege = function UserHasPrivilege(
    userPrivileges,
    privilegeToCheck
) {
    return T.IsArrayNonEmpty(userPrivileges)
        && T.IsDefined(userPrivileges.find((privs) => privs.Name === privilegeToCheck));
};
