import React, { Component } from 'react';
import ComponentModal from '../general/ComponentModal';
import * as T from '../utils/TypeUtil';

import { ESettingField, ESettingFieldLabel } from './EntityData';
import '../general/css/general.css';
import { displayError } from '../utils/ExceptionUtil';
import BootAlert from '../general/BootAlert';
import { Col, Input, Row } from 'reactstrap';
import BootPanel from '../general/BootPanel';
import {
    buildInputLabel, buildInputSelect, buildInputString,
    renderButton, renderFetchStatus
} from '../utils/EditorInputUtil';
import { fetchAppInfo, fetchAppSettings, fetchLoginsWithPrivileges, formatLogins, saveAppSettings } from './EntityUtil';
import { EPrivilege } from '../AppEnums';
import { EColor } from '../utils/DataFormatUtil';
const FETCH_PROCESSORS = 'fetchProcessors';
const FETCH_VERIFIERS = 'fetchVerifier';
const OFFICE_OPEN = 'officeOpen';
const OFFICE_CLOSE = 'officeClose';

/**
 * @param loginUserId ID of the login user
 * @param name [optional] component name
 * @param disableLoadOnInit
 * @param link [optional]
 */
export class SettingTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refModal = undefined;
        this.refAlert = undefined;

        this.reloadTable = this.reloadTable.bind(this);
        this.handleLoadTableData = this.handleLoadTableData.bind(this);
        this.handleInputChanged = this.handleInputChanged.bind(this);
        this.handleSaveButtonClicked = this.handleSaveButtonClicked.bind(this);

        this.onFetchStart = this.onFetchStart.bind(this);
        this.onFetchCompleted = this.onFetchCompleted.bind(this);
        this.updateFetchStatus = this.updateFetchStatus.bind(this);

        const { link, name } = this.props;
        this.name = name || 'tableSetting';

        this.state = {
            data: [],
            fetchProcessors: {
                options: [],
                loading: false,
            },
            fetchVerifiers: {
                options: [],
                loading: false,
            },
            appInfo: undefined,
            appInfoError: undefined,
        };

        if (T.IsFunc(link)) {
            link(this);
        }
    }

    componentDidMount() {
        this.mount = true;
        if (!T.DefaultBool(this.props.disableLoadOnInit, false)) {
            this.reloadTable();
        }
    }
    componentWillUnmount() {
        this.mount = false;
    }

    reloadTable() {
        this.onFetchStart(FETCH_PROCESSORS, () => {
            this.onFetchStart(FETCH_VERIFIERS, () => {

                fetchLoginsWithPrivileges(
                    [
                        EPrivilege.ProcessPolicy,
                        EPrivilege.VerifyPolicy,
                    ],
                    (result, error) => {
                        //if (!this.mount) return;

                        Object.keys(result).forEach(key => {
                            result[key] = formatLogins(result[key]);
                        });
                        const processors = result[EPrivilege.ProcessPolicy];
                        const verifiers = result[EPrivilege.VerifyPolicy];

                        this.onFetchCompleted(FETCH_PROCESSORS,
                            processors,
                            error,
                            () => {
                                this.onFetchCompleted(FETCH_VERIFIERS,
                                    verifiers,
                                    error,
                                    this.handleLoadTableData);
                            });
                    });
            });
        });
        fetchAppInfo((result) => {
            const details = Object.keys(result).map(x => `${x}=${result[x]}`);
            this.setState({
                appInfo: details,
                appInfoError: undefined
            });
        }, (error) => {
            this.setState({
                appInfo: undefined,
                appInfoError: error
            });
        });
    }

    onFetchStart(name, callback) {
        this.updateFetchStatus(name, true, undefined, undefined, callback);
    }
    onFetchCompleted(name, options, error, callback) {
        this.updateFetchStatus(name, false, options, error, callback);
    }
    updateFetchStatus(name, loading, options, error, callback) {
        const fetchStatus = this.state[name];
        const updated = {
            ...fetchStatus,
            ...{
                loading,
                options: options || [],
                error
            }
        };
        this.setState({ [name]: updated },
            () => {
                if (T.IsFunc(callback)) callback();
            });
    }

    handleLoadTableData() {
        this.refAlert.hide();

        const msg = 'Error retrieving application settings.';
        fetchAppSettings(
            (result) => {
                const updated = { data: result };

                const sOfficeHours = result[ESettingField.Office_Hours];
                if (!T.stringIsNullOrEmpty(sOfficeHours)) {
                    const tokens = sOfficeHours.split('-');
                    if (tokens.length === 2) {
                        updated[OFFICE_OPEN] = tokens[0];
                        updated[OFFICE_CLOSE] = tokens[1];
                    } else {
                        updated[OFFICE_OPEN] = '';
                        updated[OFFICE_CLOSE] = '';
                    }
                }
                this.setState(updated);
            },
            (error) => {
                displayError(this.refAlert, msg, error);
            });
    }

    handleSaveButtonClicked() {
        this.refAlert.hide();

        const { data } = this.state;

        data[ESettingField.Office_Hours] = `${this.state[OFFICE_OPEN]}-${this.state[OFFICE_CLOSE]}`;

        saveAppSettings(data,
            (result) => {
                this.refModal.Success('Successfully saved settings');
            },
            (error) => {
                displayError(this.refAlert, 'Error saving settings', error);
            });
    }

    handleInputChanged(evt, field, value) {
        const { data } = this.state;
        if (data[field] === value) return;

        const newData = {
            ...data,
            [field]: value
        };

        this.setState({
            data: newData
        }, () => {
        });
    }

    render() {
        const {
            data,
            officeOpen, officeClose,
            errors,
            fetchProcessors,
            fetchVerifiers,
            appInfo,
            appInfoError
        } = this.state;
        const keyPrefix = `${this.name}_eTable-`;
        const isReadOnly = false;

        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModal = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <BootPanel head='Policy' cardCname='mb-4' open>
                    <Row className='mb-2'>
                        <Col className='col-sm-4'>
                            {
                                buildInputSelect(keyPrefix, data, errors,
                                    {
                                        name: ESettingField.Policy_Monitor,
                                        label: ESettingFieldLabel.Policy_Monitor
                                    },
                                    fetchProcessors.options,
                                    this.handleInputChanged,
                                    isReadOnly,
                                    true //add invalid option
                                )
                            }
                            {
                                renderFetchStatus(fetchProcessors.loading, fetchProcessors.error)
                            }
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col className='col-sm-4'>
                            {
                                buildInputSelect(keyPrefix, data, errors,
                                    {
                                        name: ESettingField.Policy_Processor,
                                        label: ESettingFieldLabel.Policy_Processor
                                    },
                                    fetchProcessors.options,
                                    this.handleInputChanged,
                                    isReadOnly,
                                    true //add invalid option
                                )
                            }
                            {
                                renderFetchStatus(fetchProcessors.loading, fetchProcessors.error)
                            }
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col className='col-sm-4'>
                            {
                                buildInputSelect(keyPrefix, data, errors,
                                    {
                                        name: ESettingField.Policy_Verifier,
                                        label: ESettingFieldLabel.Policy_Verifier
                                    },
                                    fetchVerifiers.options,
                                    this.handleInputChanged,
                                    isReadOnly,
                                    true //add invalid option
                                )
                            }
                            {
                                renderFetchStatus(fetchVerifiers.loading, fetchVerifiers.error)
                            }
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col className='col-sm-4'>
                            {
                                buildInputLabel('Office Hours', true)
                            }
                            <Row>
                                <Col className='col-sm-3'>
                                    <Input
                                        value={T.IsNum(officeOpen) ? officeOpen : (officeOpen || '')}
                                        required
                                        key={keyPrefix + OFFICE_OPEN}
                                        readOnly={isReadOnly} disabled={isReadOnly}
                                        type='number'
                                        min={0}
                                        max={23}
                                        step={1}
                                        onChange={(evt) => {
                                            let val = evt.target.value;
                                            if (val < 0 || val === '') {
                                                val = 0;
                                            }
                                            else if (val > 23) {
                                                val = 23;
                                            }
                                            this.setState({ officeOpen: val });
                                        }}
                                    />
                                </Col>
                                <Col className='col-sm-2'>
                                    to
                                </Col>
                                <Col className='col-sm-3'>
                                    <Input
                                        value={T.IsNum(officeClose) ? officeClose : (officeClose || '')}
                                        required
                                        key={keyPrefix + OFFICE_CLOSE}
                                        readOnly={isReadOnly} disabled={isReadOnly}
                                        type='number'
                                        min={0}
                                        max={23}
                                        step={1}
                                        onChange={(evt) => {
                                            let val = evt.target.value;
                                            if (val < 0 || val === '') {
                                                val = 0;
                                            }
                                            else if (val > 23) {
                                                val = 23;
                                            }
                                            this.setState({ officeClose: val });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col className='col-sm-4'>
                            {
                                buildInputString(keyPrefix, data, errors,
                                    {
                                        name: ESettingField.After_Hour_Users,
                                        label: ESettingFieldLabel.After_Hour_Users
                                    },
                                    this.handleInputChanged,
                                    isReadOnly)
                            }
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col className='col-sm-12 mb-2' style={{ textAlign: 'right' }}>
                            {
                                renderButton(`${keyPrefix}-btnSave`, `Save`,
                                    () => this.handleSaveButtonClicked(),
                                    false)
                            }
                        </Col>
                    </Row>
                </BootPanel>
                <BootAlert canToggle={false} visible message='Application Information'
                    footer={appInfoError || appInfo}
                    color={appInfoError ? EColor.Danger : EColor.Info}
                />
            </React.Fragment>
        );
    }
}
