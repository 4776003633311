import React, { forwardRef, useState } from 'react';
import { Col, Input, InputGroup, Row } from 'reactstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { renderOptionsCell } from '../user/EntityEditorUtil';
import * as T from '../utils/TypeUtil';
import { v4 as uuid } from 'uuid';

const FileUploadMultiple = forwardRef(({ accept, multiple, onChange }, ref) => {
    const [fileList, setFileList] = useState(null);
    const [uid, setUid] = useState(uuid());
    const isMultitple = T.DefaultBool(multiple, false);

    const handleFileChange = (e) => {

        const updated = isMultitple && fileList ? [...fileList] : [];
        for (let file of e.target.files) {

            if (updated.find((x) => file.name === x.file.name
                && file.lastModified === x.file.lastModified
                && file.type === x.file.type
                && file.size === x.file.size
            )) {
                continue;
            }
            let name = file.name;
            const fileExt = name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            if (!T.stringIsNullOrEmpty(fileExt)) {
                name = name.replace(`.${fileExt}`, '');
            }
            updated.push({
                file,
                name: T.stringIsNullOrEmpty(name) ? file.name : name,
                type: file.type
            });
        }
        updateFilelist(updated);

        if (isMultitple) {
            e.target.files = undefined;
            setUid(uuid());
        }
    };

    const updateFilelist = (updated) => {

        setFileList(updated);

        if (T.IsFunc(onChange)) {
            onChange(updated);
        }
    };

    const handleFileNameChange = (newName, fileIdx) => {
        const updated = fileList ? [...fileList] : [];
        updated[fileIdx].name = newName;
        updateFilelist(updated);
    };

    const handleDeleteFileBtnClick = (fileIdx) => {
        const updated = fileList || [];
        const file = updated[fileIdx];
        updateFilelist(updated.filter((x) => x !== file));
    };

    // 👇 files is not an array, but it's iterable, spread to get an array of files
    const files = fileList ? [...fileList] : [];

    return (
        <div>
            <Input type='file' multiple={isMultitple}
                className='mb-2'
                onChange={handleFileChange}
                key={uid}
                accept={accept}
            />
            {
                isMultitple &&
                files.map((file, i) => (
                    <Row key={i}>
                        <Col className='col-sm-4'>{file.type}</Col>
                        <Col className='col-sm-8'>
                            <InputGroup>
                                <Input
                                    value={file.name}
                                    id={`file-${i}`}
                                    name={`file-${i}`}
                                    key={`file-${i}`}
                                    onChange={(evt) => { handleFileNameChange(evt.target.value, i) }}
                                />
                                {
                                    renderOptionsCell(`file-${i}-btn`,
                                        [
                                            {
                                                name: 'Delete',
                                                faIcon: faXmark,
                                                onClick: () => { handleDeleteFileBtnClick(i) }
                                            }
                                        ])
                                }
                            </InputGroup>
                        </Col>
                    </Row>
                ))
            }
        </div>
    );
})

export default FileUploadMultiple;