import React from 'react';
import {
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';

import * as T from '../utils/TypeUtil'

//tabInfos: array of {id, title, body, navLinkClassnames }
export const TabControl = (props) => {

    const { activeTabId, tabInfos, link, onTabSelectionChanged } = props;

    // Set up the capability for this component to be controlled from a parent component.
    if (T.IsFunc(link)) {
        link(this);
    }

    const activeTab = T.IsDefined(activeTabId)
        ? activeTabId
        : T.IsArrayNonEmpty(tabInfos)
            ? tabInfos[0].id
            : undefined;

    const toggle = tab => {
        if (activeTabId !== tab.id && T.IsFunc(onTabSelectionChanged)) {
            onTabSelectionChanged(tab.id);
        }
    }

    return (
        <React.Fragment>
            <Nav tabs className='mb-4'>
                {
                    tabInfos.map(tab => {
                        const { id, title, navLinkClassnames } = tab;
                        let classNames = id === activeTab
                            ? 'active '
                            : '';
                        if (T.IsDefined(navLinkClassnames)) {
                            classNames += navLinkClassnames;
                        }

                        return (
                            <NavItem key={`nav-${id}`}>
                                <NavLink
                                    className={classNames}
                                    onClick={() => { toggle(tab); }}
                                >
                                    {
                                        T.IsFunc(title) ? title() : title
                                    }
                                </NavLink>
                            </NavItem>
                        );
                    })
                }
            </Nav>
            <TabContent activeTab={activeTab}>
                {
                    tabInfos.map(tab => {

                        const { id, body } = tab;
                        return (
                            <TabPane tabId={id} key={`nav-pane-${id}`}>
                                {
                                    T.IsFunc(body)
                                        ? body()
                                        : body
                                }
                            </TabPane>
                        );
                    })
                }
            </TabContent>
        </React.Fragment>
    );
}